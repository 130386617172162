import {useState} from 'react';
import {useLayoutEffect} from 'react';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {IDLE_TIMEOUT} from '../../Constants';

/**
 * Hook to call for idle time detection.
 *
 * @param onIdle Called when the user is idle.
 * @param onWakeup Called when no longer idle.
 * @param timeout The time of inactivity that is considered idle.
 */
export default function useIdleTime(onIdle, onWakeup, timeout = IDLE_TIMEOUT) {
   const [time, setTime] = useState();
   const [isIdle, setIsIdle] = useState(false);

   /**
    * Reset the timer when user activity occurs.
    * @type {(function(): void)|*}
    */
   const resetTimer = useCallback(() => {
      if (isIdle) {
         onWakeup?.();
         setIsIdle(false);
      }
      clearTimeout(time);
      setTime(setTimeout(() => {
         setIsIdle(true);
         onIdle?.();
      }, timeout));
   }, [isIdle, onIdle, onWakeup, time, timeout]);

   /**
    * Handles listeners for user events.
    */
   useEffect(() => {
      window.addEventListener('onload', resetTimer);
      document.addEventListener('keydown', resetTimer, false);
      document.addEventListener('mousedown', resetTimer, false);
   }, [resetTimer]);

   /**
    * Cleanup the listeners when this component is removed. This is needed because of a bug in react. Should be able to
    * do this from UseEffect.
    */
   useLayoutEffect(() => {
      return () => {
         window.removeEventListener('onload', resetTimer);
         document.removeEventListener('keydown', resetTimer, false);
         document.removeEventListener('mousedown', resetTimer, false);
      }
   }, [resetTimer]);
}
