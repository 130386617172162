import {Fab} from '@material-ui/core';
import {Grid} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import gql from 'graphql-tag';
import {delay} from 'lodash';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useMemo} from 'react';
import {useRef} from 'react';
import {useLayoutEffect} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import React from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {PLACEHOLDER_IMAGE, MAX_IMAGES, VIDEO_DELAY, SCREEN_SAVER_TIMEOUT, MIN_IMAGES} from '../../Constants';
import {errorState} from '../../fhg/components/ErrorStateSnackbar';
import Video from '../../fhg/components/Video';
import {UPDATE_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import {ADD_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {cacheUpdate} from '../../fhg/utils/DataUtil';
import {cacheAdd} from '../../fhg/utils/DataUtil';
import {base64toBlob, formatMessage} from '../../fhg/utils/Utils';
import EmployeeInfo from './EmployeeInfo';
import {Prompt} from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
   data: {
      height: '100%',
   },
   progress: {
      marginTop: 150,
   },
   root: {
      overflow: 'hidden',
      height: '100%'
   },
   paperStyle: {
      overflow: 'hidden',
      padding: '16px  !important',
      margin: '6px 16px 16px 16px',
      height: 'calc(100% - 86px) !important',
      width: 'calc(100% - 64px) !important',
   },
   mainGridStyle: {
      flex: '0 0 auto',
   },
   gridList: {
      // flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
   },
   titleBar: {
      height: 20,
      background:
         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
         'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   info: {
      marginTop: 16,
      paddingLeft: 8,
      borderLeft: `2px solid ${theme.palette.divider}`,
   },
   icon: {
      paddingRight: 0,
      color: 'white',
   },
   image: {
      '&:after': {
         content: '""',
         position: 'absolute',
         top: 2,
         left: 2,
         right: 2,
         bottom: 2,
         border: `4px solid ${theme.palette.primary.dark}`,
         zIndex: 1001,
      }
   },
   outerGridStyle: {
      flex: '1 1 auto',
   },
   innerGridStyle: {
      width: '100%',
      overflow: 'auto',
      flex: '0 0 auto',
   },
   innerGridStyle2: {
      minHeight: 330,
      marginTop: 16,
      marginRight: 8,
      maxWidth: 'calc(50% - 17px)',
      flexBasis: 'calc(50% - 17px)',
   },
   innerGridStyle3: {
      height: 'calc(100% - 8px)',
   },
   videoFrameStyle: {
      display: 'flex',
   },
   videoStyle: {
      flex: '1 1 100%',
      transform: 'none', left: 'auto', top: 'auto',
   },
   buttonFrameStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
   },
   labelFrameStyle: {
      textAlign: 'center'
   },
}), {name: 'EmployeeStyles'});

let timer;

/**
 * Create the list of images as blobs from the faces property in the employee returned by the server.
 *
 * @param faces The list of faces.
 * @return {Array}  Array of image blobs.
 */
const loadFaces = (faces = []) => {
   const facesCount = faces.length ?? 0;
   const images = [];

   if (facesCount > 0) {

      for (let i = 0; i < facesCount; i++) {
         const blob = base64toBlob(faces[i].image, 'image/jpeg', 512);
         const image = {
            id: faces[i].id,
            src: blob && URL.createObjectURL(blob),
            blob: blob,
         };

         images.push(image);
      }
   }
   if (facesCount < MAX_IMAGES) {
      for (let i = facesCount; i < MAX_IMAGES; i++) {
         images.push({src: PLACEHOLDER_IMAGE});
      }
   }

   return images;
};

/**
 * The Employee component to edit the employee photos and information.
 */
export default function Employee() {
   const classes = useStyles();
   const {id} = useParams();

   const history = useHistory();
   const intl = useIntl();
   const videoRef = useRef();

   const [data] = useQueryFHG(GetEmployee, {variables: {id: +id}, skip: id === undefined, errorPolicy: 'all'});
   const employee = data?.employee;

   const [selectedIndex, setSelectedIndex] = useState();
   const [deletedIndex, setDeletedIndex] = useState();
   const [addedIndex, setAddedIndex] = useState();
   const [showVideo, setShowVideo] = useState(true);
   const [isCameraEnabled, setIsCameraEnabled] = useState(false);
   const [isShowCameraButton, setIsShowCameraButton] = useState(true);
   const [deletedFaces, setDeletedFaces] = useState([]);
   const [addedFaces, setAddedFaces] = useState([]);
   const [isSaving, setIsSaving] = useState(false);
   const [images, setImages] = useState(loadFaces(employee?.faces));
   const [image, setImage] = useState();
   const [isInfoChanged, setIsInfoChanged] = useState(false);
   const setErrorState = useSetRecoilState(errorState);

   const [addEmployee] = useMutationFHG(AddEmployee, undefined, undefined,
      'employee.add.error');
   const [updateEmployee] = useMutationFHG(UpdateEmployee, undefined, undefined,
      'employee.update.error');

   const firstPlaceholderIndex = images.findIndex(image => image.src === PLACEHOLDER_IMAGE);

   useLayoutEffect(() => {
      return () => {
         document.removeEventListener('keydown', handleKey, false);
         clearTimeout(timer);
      }
   });

   useEffect(() => {
      if (employee?.faces) {
         setImages(loadFaces(employee?.faces));
         setDeletedFaces([]);
         setAddedFaces([]);
      }
   }, [employee?.faces]);

   /**
    * Scroll the selected thumbnail into view.
    */
   const scrollIntoView = useCallback(() => {
      const elements = document.getElementsByClassName(classes.image);
      let objDiv = document.getElementById('thumbnailListId');
      if (objDiv && elements.length > 0) {
         if (objDiv.clientWidth > objDiv.clientHeight) {
            objDiv.scrollLeft = elements[0].offsetLeft - objDiv.offsetLeft - (objDiv.offsetWidth / 2) +
               (elements[0].offsetWidth / 2);
         } else {
            objDiv.scrollTop = elements[0].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
               (elements[0].offsetHeight / 2);
         }
      }
   }, [classes.image]);

   /**
    * Delete the photo at the index.
    * @param deletedIndex The index of the photo to delete.
    * @return {Function} The function that deletes photos at the index.
    */
   const onDeletePhoto = (deletedIndex) => () => {
      setDeletedIndex(deletedIndex);
   };

   /**
    * on completion of the animation update face will add the image to the deleted or added faces.
    */
   const onUpdateFace = () => {
      if (deletedIndex !== undefined) {
         const useImages = images.slice(0);
         const useDeletedFaces = useImages.splice(deletedIndex, 1);
         let newDeletedFaces;

         if (useDeletedFaces.length > 0 && useDeletedFaces[0].id) {
            newDeletedFaces = deletedFaces.concat(useDeletedFaces);
         }

         useImages.push({src: PLACEHOLDER_IMAGE});
         setImages(useImages);
         setDeletedFaces(newDeletedFaces);
         setDeletedIndex(undefined);
         selectImage(undefined);
      } else if (addedIndex !== undefined) {
         const copyImages = images.slice(0);
         const useAddedFaces = addedFaces.slice(0);
         useAddedFaces.push(image);
         copyImages[addedIndex] = image;
         const remainingIndex = copyImages.findIndex(image => image.src === PLACEHOLDER_IMAGE);
         setImages(copyImages);
         setAddedFaces(useAddedFaces);
         setShowVideo(false);
         setAddedIndex(undefined);
         setIsCameraEnabled(remainingIndex >= 0);
         setSelectedIndex(addedIndex);
         setTimeout(() => {
            setShowVideo(true);
         }, VIDEO_DELAY)
      }
   };

   /**
    * Find the first non placeholder photo to the left of index.
    * @param index The index to start looking left from.
    * @return {number}  The index of the first real photo to the left or -1 if not found.
    */
   const findLeftNonPlaceholder = useCallback((index) => {
      for (let i = index - 1; i >= 0; i--) {
         if (images[i].src !== PLACEHOLDER_IMAGE) {
            return i;
         }
      }
      return -1;
   }, [images]);

   /**
    * Find the first non placeholder photo to the right of index. It will wrap around the right end and continue from
    * the left.
    * @param index The index to start looking right from.
    * @return {number}  The index of the first real photo to the right or -1 if not found.
    */
   const findRightNonPlaceholder = useCallback(index => {
      for (let i = index + 1; i < index + MAX_IMAGES; i++) {
         if (images[i % MAX_IMAGES].src !== PLACEHOLDER_IMAGE) {
            return i % MAX_IMAGES;
         }
      }
      return -1;
   }, [images]);

   /**
    * Select the image at the index.
    */
   const selectImage = useCallback((index) => (moveLeft = true) => {
      let selectedIndex;
      const image = images?.[index]?.src;

      if (image === undefined) {
         selectedIndex = undefined;
      } else if (image === PLACEHOLDER_IMAGE) {
         if (moveLeft) {
            selectedIndex = findLeftNonPlaceholder(index);
         } else {
            selectedIndex = findRightNonPlaceholder(index);
         }
         if (selectedIndex < 0) {
            selectedIndex = undefined;
         }
      } else {
         selectedIndex = index;
      }

      setSelectedIndex(selectedIndex);
      setIsCameraEnabled(false);
   }, [findLeftNonPlaceholder, findRightNonPlaceholder, images]);

   /**
    * Handle arrow keys for selecting thumbnails.
    * @param event The key event.
    */
   const handleKey = useCallback((event) => {
      if (!event.defaultPrevented) {
         if ((event.key === 'ArrowRight' || event.key === 'ArrowDown') && event.target.type !== 'text') {
            event.preventDefault();
            selectImage((selectedIndex + 1) % images.length)(false);
            scrollIntoView();
         } else if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp') && event.target.type !== 'text') {
            event.preventDefault();
            selectImage((selectedIndex - 1) >= 0 ? (selectedIndex - 1) : images.length - 1)();
            scrollIntoView();
         }
      }
   }, [images?.length, scrollIntoView, selectImage, selectedIndex]);

   useEffect(() => {
      document.addEventListener('keydown', handleKey, false);
   }, [handleKey]);

   /**
    * Called when the camera button is clicked to take the photo.
    * @return {Promise<void>}
    */
   const onCameraClick = async () => {
      if (!isCameraEnabled) {
         if (firstPlaceholderIndex >= 0) {
            setIsCameraEnabled(true);
            setSelectedIndex(undefined)
            setIsShowCameraButton(true);
            videoRef.current.turnOn();
         }
      } else {
         const image = await videoRef.current.takePhoto();
         const emptyIndex = images.findIndex(image => image.src === PLACEHOLDER_IMAGE);
         setAddedIndex(emptyIndex);
         setImage(image);
      }
      if (timer) {
         clearTimeout(timer);
      }
      timer = delay(() => {
         videoRef.current.turnOff();
         setIsCameraEnabled(false);
         timer = undefined;
      }, SCREEN_SAVER_TIMEOUT);
   };

   /**
    * Called when the Employee component is closed. Set all changed indicators to false to the prompt doesn't show.
    */
   const onClose = () => {
      setIsInfoChanged(false);
      setAddedFaces([]);
      setDeletedFaces([]);
      setTimeout(() => {
         history.push('/admin/employees');
      }, 10);
   };

   /**
    * The subcomponent has changed the employee info.
    */
   const onInfoChanged = () => {
      setIsInfoChanged(true);
   };

   const getEmployeeCacheQueries = (id) => {
      return [
         {query: GetEmployee, variables: {id: +id}, queryPath: 'employee'}
      ];
   }

   /**
    * When the user is submitting the changes.
    * @param employee   The changed employee.
    * @return {Promise<void>}
    */
   const onSubmit = async (employee) => {
      setIsSaving(true);
      setIsCameraEnabled(false);

      if (employee) {
         const id = employee?.id;

         try {
            if (employee?.supervisorId === -1) {
               employee.supervisorId = null;
            }
            if (employee?.secondarySupervisorId === -1 || employee?.secondarySupervisorId === null) {
               employee.secondarySupervisorId = undefined;
               employee.tempSupervisorList = null;
            } else if (employee?.secondarySupervisorId || employee.expirationDate) {
               employee.tempSupervisorList = [{coveredUserId: employee.secondarySupervisorId || data?.employee?.tempSupervisorAccessList?.[0]?.coveredUserId, expirationDate: employee.expirationDate || data?.employee?.tempSupervisorAccessList?.[0]?.expirationDate}]
               employee.secondarySupervisorId = undefined;
               employee.expirationDate = undefined;
            }

            if (!id) {
               employee.files = images.filter(image => image.src !== PLACEHOLDER_IMAGE).map(image => image.blob);
               await addEmployee({
                  variables: {...employee},
                  update: cacheAdd(getEmployeeCacheQueries(employee.id), 'employee'),
               });
            } else {
               employee.id = id;
               employee.files = addedFaces && addedFaces.length > 0 ? addedFaces.map(image => image.blob) : undefined;
               employee.deletedFaceIds =
                  deletedFaces && deletedFaces.length > 0 ? deletedFaces.map(image => image.id) : undefined;
               await updateEmployee({
                  variables: {...employee},
                  update: cacheUpdate(getEmployeeCacheQueries(employee.id), employee.id, 'employee'),
               });
            }
            onClose()
         } catch (e) {
            console.log('Could not submit employee.');
            setIsSaving(false);
         }
      } else {
         setIsSaving(false);
         setErrorState({errorMessage: 'No Employee Data'});
      }
   };

   const isChanged = useMemo(() => addedFaces?.length > 0 || deletedFaces?.length > 0, [addedFaces?.length, deletedFaces?.length]);
   //if firstPlaceholderIndex is -1 there aren't any placeholder images. Make sure there are some real images.
   const isValid = (firstPlaceholderIndex === -1 && images.length > 0) ||
      (firstPlaceholderIndex >= MIN_IMAGES && firstPlaceholderIndex <= MAX_IMAGES);

   return (
      <Paper className={classes.paperStyle}>
         <Grid container className={classes.root} direction='column' spacing={0} justfy={'center'} wrap={'nowrap'}>
            <Prompt when={isChanged || isInfoChanged}
                    message={() => formatMessage(intl, 'employee.leavePage.warning', 'Discard changes?')}/>
            <Grid item xs={12} className={classes.mainGridStyle}>
               <ImageList id='thumbnailListId' className={classes.gridList} cols={5}>
                  {images.map((image, index) => (
                     <Fade key={'fade' + index} direction='up' in={deletedIndex !== index && addedIndex !== index}
                           onExited={onUpdateFace}
                           timeout={{
                              enter: addedIndex === index ? 200 : 750,
                              exit: deletedIndex === index ? 750 : 200
                           }}>
                        <ImageListItem key={index}
                                      style={{padding: 6, position: 'relative', width: 120, height: 90}}
                                      className={selectedIndex === index ? classes.image : undefined}
                        >
                           <img src={image.src} onClick={selectImage(index)} alt='Employee'/>
                           <ImageListItemBar
                              style={{
                                 zIndex: 1002,
                                 display: index < MIN_IMAGES || selectedIndex === index ? undefined : 'none'
                              }}
                              subtitle={index < MIN_IMAGES ? 'Required' : undefined}
                              position='top'
                              actionPosition={'right'}
                              actionIcon={
                                 <IconButton className={classes.icon} onClick={onDeletePhoto(index)}
                                             style={{display: selectedIndex === index ? undefined : 'none'}}>
                                    <Close/>
                                 </IconButton>
                              }
                              className={classes.titleBar}
                           />
                        </ImageListItem>
                     </Fade>
                  ))}
               </ImageList>
            </Grid>
            <Grid container className={classes.outerGridStyle} direction={'row'} wrap={'nowrap'}>
               <Grid container className={classes.innerGridStyle} direction={'row'} wrap={'nowrap'}>
                  <Grid item sm={6} className={classes.innerGridStyle2}>
                     <Grid container className={classes.innerGridStyle3} direction={'column'} spacing={2}
                           justifyContent={'center'}
                           alignItems={'center'} wrap={'nowrap'}>
                        <Grid item className={classes.videoFrameStyle}>
                           <Video className={classes.videoStyle} showVideo={isCameraEnabled && showVideo}
                                  placeHolderImage={PLACEHOLDER_IMAGE}
                                  image={selectedIndex >= 0 ? images[selectedIndex].src : undefined}
                                  ref={videoRef}
                                  onClick={!isSaving ? onCameraClick : undefined}
                           />
                        </Grid>
                        <Grid item className={classes.buttonFrameStyle}>
                           {!isCameraEnabled && (
                              <div className={classes.labelFrameStyle}>Click to Enable</div>
                           )}
                           {isShowCameraButton && (
                              <Fab color={!isCameraEnabled ? 'disabled' : 'primary'}
                                      onClick={onCameraClick} disabled={isSaving}>
                                 <PhotoCameraIcon/>
                              </Fab>
                           )}
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item xs={6} className={classes.info}>
                     <EmployeeInfo employee={employee} onSubmit={onSubmit} isParentChanged={isChanged}
                                   isParentValid={isValid} isEnabled={!isSaving} onChange={onInfoChanged}/>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Paper>
   );
}

export const EmployeeInfoFragment = gql`
   fragment EmployeeInfo on User {
      id
      firstName
      lastName
      lastTwoClocks {
         id
         loggedTime
         location:costCenter {
            id
            name
         }
      }
   }
`;

export const EmployeeBriefInfoFragment = gql`
   fragment EmployeeBriefInfo on User {
      id
      firstName
      lastName
      username
      password
      roleId
      supervisorId
      tempSupervisorAccessList {
         coveredUserId
         expirationDate
      }
      adpId
      faces {
         id
         image
      }
   }
`;

export const GetEmployees = gql`
   query getEmployees {
      employees:user_All {
         ...EmployeeInfo
      }
   }
   ${EmployeeInfoFragment}
`;

const GetEmployee = gql`
   query getEmployee ($id: Int!) {
      employee:user_ById(userId: $id) {
         ...EmployeeBriefInfo
      }
   }
   ${EmployeeBriefInfoFragment}
`;

const AddEmployee = {
   mutation: gql`
      mutation addEmployee($roleId: Int, $supervisorId: Int, $adpId: String, $firstName: String!, $lastName: String!, $username: String!, $password: String!, $files: [Upload], $tempSupervisorList: [TempSupervisorUpdateInput]) {
         employee: user_Create(user: {roleId: $roleId, supervisorId: $supervisorId, adpId: $adpId, firstName: $firstName, lastName: $lastName, username: $username, password: $password, files: $files, tempSupervisorAccessList: $tempSupervisorList}) {
            ...EmployeeBriefInfo
         }
      }
      ${EmployeeBriefInfoFragment}
   `,
   typeKey: 'employee.type',
   actionKey: ADD_ACTION,
};

const UpdateEmployee = {
   mutation: gql`
      mutation updateEmployee(
         $id: Int!, $roleId: Int, $supervisorId: Int, $adpId: String, $firstName: String, $lastName: String, $username: String, $password: String, $files: [Upload], $deletedFaceIds: [Int], $tempSupervisorList: [TempSupervisorUpdateInput]) {
         employee: user_Update(userId: $id, user: {roleId: $roleId, supervisorId: $supervisorId, adpId: $adpId, firstName: $firstName, lastName: $lastName, username: $username, password: $password, files: $files, deletedFaceIds: $deletedFaceIds, tempSupervisorAccessList: $tempSupervisorList}){
            ...EmployeeBriefInfo
         }
      }
      ${EmployeeBriefInfoFragment}
`,
   typeKey: 'employee.type',
   actionKey: UPDATE_ACTION,
};

Employee.propTypes = {
   employee: PropTypes.object,               // The employee to edit.
};

