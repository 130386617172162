import {useLazyQuery} from '@apollo/react-hooks';
import uniqueId from 'lodash/uniqueId';
import {useRef} from 'react';
import {useSetRecoilState} from 'recoil';
import {useEffect} from 'react';
import {errorState} from '../../components/ErrorStateSnackbar';
import useProgress from '../useProgress';

export default function useLazyQueryFHG(query, options, errorKey, showLoading) {
   const theUniqueId = useRef(uniqueId()).current;
   const [/*Unused*/, setProgress] = useProgress(theUniqueId);

   const setErrorState = useSetRecoilState(errorState);

   const [queryFunction, {loading, error, data}] = useLazyQuery(query, options);

   useEffect(() => {
      return () => {
         setProgress(false);
      }
   }, [setProgress]);

   useEffect(() => {
      if (showLoading) {
         setProgress(loading);
      }
   }, [loading, setProgress, showLoading]);

   useEffect(() => {
      if (error) {
         console.log(error, error.stackTrace);

         if (errorKey) {
            console.log('Error', errorKey);
            setErrorState({error, errorKey, values: {message: error.message}});
         }
      }
   }, [error, setErrorState, errorKey]);

   return [queryFunction, {loading, error, data}];
}
