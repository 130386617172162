import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Typography from '../../fhg/components/Typography';

/**
 * Edit the locations (cost centers) of the clock in /out browsers.
 */
export default class EditLocation extends Component {

   static propTypes = {
      open: PropTypes.bool.isRequired,       // Indicates if the dialog is showing.
      onSubmit: PropTypes.func.isRequired,   // Callback when the user submits changes.
      onClose: PropTypes.func.isRequired,    // Callback when the user closes the edit location.
      location: PropTypes.object,            // The location object from the server.
   };

   static defaultProps = {
      location: {},
   };

   constructor(props) {
      super(props);

      this.state = {
         location: this.props.location,
         name: this.props.location.name,
         adpId: this.props.location.adpId,
         isChanged: false,
      };
      document.addEventListener('keydown', this.handleKey, false);
   }

   // noinspection JSCheckFunctionSignatures
   componentWillReceiveProps(nextProps) {
      // Check for a different location or a new location.
      if (nextProps.location.id !== this.props.location.id || nextProps.location.id < 0) {
         const { location, location : {adpId = '', name = ''}} = this.props;
         this.setState({location, name, adpId});
      }
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   /**
    * Handle enter and escape key default actions.
    * @param event The key event.
    */
   handleKey = (event) => {

      if (this.props.open) {
         if (!event.defaultPrevented) {
            if (event.key === 'Enter' && this.state.isChanged) {
               event.preventDefault();
               this.onSubmit();
            }
         } else if (event.key === 'Escape') {
            event.preventDefault();
            this.props.onClose();
         }
      }
   };

   /**
    * Handle changes to the item.
    * @param target The target of the event that triggered the change.
    */
   onChange = ({ target }) => {
      this.setState({ [ target.name ]: target.value, isChanged: true });
   };

   /**
    * Handle submit from the user.
    */
   onSubmit = () => {
      const { onSubmit } = this.props;
      const { location, name, adpId } = this.state;

      if (onSubmit) {
         onSubmit({...location, name, adpId});
      }
   };

   render() {
      const { open, onClose } = this.props;
      const { location, isChanged, name, adpId } = this.state;
      return (
         <Dialog
            disableEscapeKeyDown={true}
            open={open}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
         >
            <DialogTitle id='form-dialog-title'>{location.id >= 0 ? 'Edit' : 'Add'} Location</DialogTitle>
            <DialogContent>
               <DialogContentText>
                  <Typography variant={'inherit'} id='location.subtitle.text'/>
               </DialogContentText>
               <TextField
                  name={'name'}
                  autoFocus
                  value={name}
                  onChange={this.onChange}
                  margin='dense'
                  label={<Typography variant='inherit' id={'location.name.label'}/>}
                  type='text'
                  fullWidth
               />
               <TextField
                  name={'adpId'}
                  value={adpId}
                  onChange={this.onChange}
                  margin='dense'
                  label={<Typography variant='inherit' id={'location.adp.label'}/>}
                  type='text'
                  fullWidth
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={onClose} color='primary'>
                  Cancel
               </Button>
               <Button onClick={this.onSubmit} disabled={!isChanged} color='primary'>
                  {location.id >= 0 ? 'Update' : 'Add'}
               </Button>
            </DialogActions>
         </Dialog>
      );
   }
}
