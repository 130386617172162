const messages = {
   // General
   "refresh": 'Refresh',
   "validate.button.label": 'Validate',
   "cancel.button": 'Cancel',
   "confirmRemove.title": 'Delete {type}',
   'required.label': '* Required',
   'delete.button': 'Delete',

   // Query
   "fetch.error": 'Could not load data. ({message})',
   "submit.error": 'Could not save data. ({message})',
   'load.error': 'Could not load {type}',
   'action.error': 'Could not {action} the {type}.',
   'add': "add",
   'set': "set",
   'create': "create",
   'update': "update",
   'createUpdate': "create or update",
   'delete': "delete",
   'load': "load",

   // Types
   'employee.type': 'employee',
   'clock.type': 'clock options',
   'checkFace.type': 'identity',
   'clockIn.type': 'clock in',
   'location.type': 'location',
   'confidenceLevel.type': 'confidence level',

   'copyright': '© 2021 Starkey, inc',

   'noMatch': 'Your picture was not recognized. \nEither: 1) Enter your username and password, \nOr 2) select Cancel and start the recognition again.',
   'login.error': 'Bad password or invalid username.',
   'login.username': 'Username',
   'login.password': 'Password',
   'confirm.message': 'Looks like you are {firstName} {lastName}?',
   'success.message': 'You are now clocked {inOut}.',
   'noMatch.confirm.message': 'Login successful.',
   'noMatch.title': 'No Match',

   // Main
   "main.isAdmin.error": 'Cannot access server ({message})',

   //Security
   "logout": 'Log Out',
   "path.logout": 'Log Out',
   'login.confirm.error': 'Password and confirmation do not match.',
   'login.noPassword.error': 'A valid password is required.',
   'login.passwordQuality.error': 'Password should contain at least two of the following: lowercase, uppercase, number, symbol (.-_$*()#@!%/ )',
   'login.passwordLength.error': 'Password should be between 8 and 20 characters',
   // eslint-disable-next-line
   'login.passwordCharacters.error': 'Invalid character entered ([;<>\\{}[\]+=?&,:\'"`)',
   'login.location.label':  'Location of this installation',
   'login.checkAdmin.error': 'Could check admin for user.',

   //Clock In / Out
   "clock.title": 'Clock In/Out',
   "clock.pay.label": 'Job',
   "clock.pay.normal.label": 'Department Location',
   "clock.pay.extra.label": 'Job #1 Traditional',
   "clock.in.label": 'Clock In/Transfer',
   "clock.out.label": 'Clock Out',
   "clock.date.label": 'Current Date/Time: {dateTime}',
   "clock.log.error": 'Could not log in with the server.',
   "clock.clock.error": 'Could not register clock in/out with the server.',
   'clock.checkMatch.error': 'Cannot match employee. ({message})',
   'clock.laborLevels.error': 'Cannot get jobs. ({message})',
   'clock.noLocationSet.error': 'The default location has not been set.',

   //Locations
   "location.defaultLocation.column": 'Default for Location',
   "location.name.column": 'Name',
   "location.name.label": 'Name',
   "location.adp.column": 'ADP Number',
   "location.adp.label": 'ADP Number',
   'location.get.error': 'Cannot get the locations. ({message})',
   'location.add.error': 'Cannot add the location. ({message})',
   'location.update.error': 'Cannot update the location. ({message})',
   'location.delete.confirm': 'Delete the location, {name}?',
   'location.delete.error': 'Cannot delete the location. ({message})',
   'location.subtitle.text': 'Enter the typical name for the facility.',

   // Audit Report
   'audit.date.column': 'Date',
   'audit.clockIn.column': 'Action',
   'audit.logPhoto.column': 'Captured',
   'audit.type.column': 'Type',
   'audit.photoMatch.column': 'Username Entered',
   'audit.employeeIdentified.column': 'Identified',
   'audit.confidence.column': 'Confidence',
   'audit.enteredFace.column': 'Recorded Punch',
   'audit.enteredName.column': 'Recorded Name',
   'audit.matchedFace.column': 'Recognized',
   'audit.matchedName.column': 'Recognized Name',
   'audit.location.column': 'Location',
   'audit.laborLevel.column': 'Job',
   'audit.getAuditEntries.error': 'Could not get audit entries',
   'audit.saveConfidenceLevel.error': 'Could not save the confidence level.',
   "audit.passwordOnly.label": "Password Only",
   'audit.customDate.label': "Date Range",
   'audit.emptyTable.label': "No Audit Entries available. Change the date, password only or show direct reports filters, to see more audit entries.",

   // Employees
   'employees.photo.column': 'Photo',
   "employees.firstName.column": 'First Name',
   "employees.lastName.column": 'Last Name',
   "employees.location.column": 'Location',
   "employees.clockedIn.column": 'Clocked In',
   "employees.clockedOut.column": 'Clocked Out',
   "employees.notAdmin.error": 'The user is not an administrator or supervisor.',
   "employees.showPhoto.label": "Download Photos",
   "employees.showDirectReports.label": "Direct Reports",

   // Employee
   'employee.add.error': 'Cannot add employee. ({message})',
   'employee.update.error': 'Cannot update the employee. ({message})',
   'employee.delete.confirm': 'Delete the employee, {name}?',
   'employee.delete.button': 'Delete',
   'employee.delete.error': 'Cannot delete the employee. ({message})',
   'employee.addPhoto.error': 'Cannot add the photo. ({message})',
   'employee.deletePhoto.error': 'Cannot delete the photo. ({message})',
   'employee.deletePhoto.confirm': 'Permanently delete the photo?',
   'employee.checkUsername.error': 'Cannot check the username.',
   'employee.getSupervisors.error': 'Cannot get supervisors.',
   'employee.title.label': 'Employee Information',
   'employee.leavePage.warning': 'You have made changes, which have not been saved. Do you want to discard changes?',
   'employee.firstName.label': 'First Name',
   'employee.lastName.label': 'Last Name',
   'employee.adpId.label': 'ADP ID',
   'employee.username.label': 'Username',
   'employee.confirmPassword.label': 'Confirm',
   'employee.newPassword.label': 'Password',
   'employee.changePassword.label': 'Change Password',
   'employee.add.label': 'Add Employee',
   'employee.update.label': 'Update Employee',

   'user.password.label': 'Password',
   'user.confirm.label': 'Confirm',
   'user.changePassword.label': 'Change Password',
   "user.confirmMismatch.message": "Confirm does not match the password.",
   "user.usernameEdit.message": "Cannot edit usernames of existing users.",
};

export  default messages;
