import {Grid} from '@material-ui/core';
import Button from '@material-ui/core//Button';
import InputAdornment from '@material-ui/core//InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CheckCircle from '@material-ui/icons/CheckCircle';
import crypto from 'crypto';
import gql from 'graphql-tag';
import {debounce} from 'lodash';
import find from 'lodash/find';
import {useRef} from 'react';
import {useState} from 'react';
import {useLayoutEffect} from 'react';
import {useEffect} from 'react';
import {useMemo} from 'react';
import React from 'react';
import {USERNAME_CHECK_INTERVAL} from '../../Constants';
import DatePickerFHG from '../../fhg/components/DatePickerFHG';
import Form from '../../fhg/components/edit/Form';
import useEditData from '../../fhg/components/edit/useEditData';
import PasswordTextField from '../../fhg/components/security/PasswordTextField';
import Typography from '../../fhg/components/Typography';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import capitalize from 'lodash/capitalize';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
   data: {
      height: '100%',
   },
   button: {
      margin: theme.spacing(1),
   },
   unsizingFlex: {
      flex: '0 0 auto',
   },
   flex: {
      display: 'flex',
   },
   stickyCaption: {
      position: 'sticky',
      left: 0,
   },
   resizingContainer: {
      display: 'flex',
      flex: '1 1',
      alignItems: 'center',
      justifyContent: 'center',
   },
   progressMargin: {
      marginLeft: theme.spacing(0.5),
   },
   titleStyle: {
      textAlign: 'center',
   },
   inlineStyle: {
      display: 'inline-block',
   },
   selectStyle: {
      marginTop: 3,
   },
   formStyle: {
      maxHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
   },
}), {name: 'EmployeeInfoStyles'});

/**
 * The component that shows the employee specific fields. Primary purpose is to connect employee to account on ADP and
 * username and password to match employee when connection to ADP cannot be made.
 */
export default function EmployeeInfo({
   employee,
   isParentValid,
   isParentChanged,
   isEnabled,
   onChange,
   onSubmit
}) {
   const classes = useStyles();

   const [hasUsernameConflict, setHasUsernameConflict] = useState(false);
   const [isSaving, setIsSaving] = useState(false);

   const [errorId, setErrorId] = useState();

   const [
      editValues, handleChange, {
         isChanged,
         getValue,
         resetValues,
         defaultValues,
         setDefaultValues
      }
   ] = useEditData(employee, undefined, undefined, () => {
      onChange?.();
   });

   const [data] = useQueryFHG(GetSupervisors);
   const [dataRoles] = useQueryFHG(GetRoles, {variables: {}});

   const supervisors = useMemo(() => data.supervisors, [data]);

   const roles = useMemo(() => dataRoles.roles, [dataRoles]);

   const [checkUsernameQuery, {data: dataCheckUsername}] = useLazyQueryFHG(CheckUsername,
      {fetchPolicy: 'network-only'});

   useEffect(() => {
      if (dataCheckUsername?.hasUsernameConflict !== undefined) {
         setHasUsernameConflict(dataCheckUsername?.hasUsernameConflict);
      } else {
         setHasUsernameConflict(false);
      }
   }, [dataCheckUsername?.hasUsernameConflict]);

   useEffect(() => {
      if (employee) {
         setDefaultValues({
            ...employee,
            supervisorId: employee.supervisorId || -1,
            expirationDate: employee?.tempSupervisorAccessList?.[0]?.expirationDate,
            secondarySupervisorId: employee?.tempSupervisorAccessList?.[0]?.coveredUserId
         });
      }
   }, [employee, setDefaultValues]);

   const invokeCheckUsername = (username, originalUsername) => {
      try {
         if (username !== originalUsername) {
            checkUsernameQuery({variables: {username}});
         } else {
            //If the user hasn't changed the username we don't need to check and there isn't a conflict.
            setHasUsernameConflict(false);
         }
      } catch (e) {
         console.log('Could not check username for conflicts.');
         console.log(e);
      }
   };

   /**
    * Check the username against the servers list of usernames. Debounce the check to cut down on frequency of checks.
    */
   const checkUsernameDebounced = useRef(debounce(invokeCheckUsername, USERNAME_CHECK_INTERVAL)).current;

   /**
    * When the component unmounts stop the username check.
    */
   useLayoutEffect(() => {
      return () => {
         checkUsernameDebounced.cancel();
         resetValues();
         setErrorId(undefined);
      }
   }, [checkUsernameDebounced]);

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param event The change password event.
    */
   const handleUsernameChange = (event) => {
      const result = handleChange(event);

      // Update the validity based on edit changes.
      checkUsernameDebounced(result?.username, defaultValues?.username);
   };

   /**
    * Callback when the user submits the changes.
    */
   const handleSubmit = async () => {
      let password = editValues?.password;

      setIsSaving(true);
      try {
         checkUsernameDebounced.flush();

         if (hasUsernameConflict) {
            return;
         }

         if (password && password.trim() !== '') {
            // const key = '';
            // // noinspection JSUnresolvedFunction
            // const cipher = crypto.createCipher('aes128', key);
            // let hash = cipher.update(password.trim(), 'utf8', 'hex');
            // // noinspection JSUnresolvedFunction
            // hash += cipher.final('hex');
            // password = hash;
            // editValues.password = password;
         }

         try {
            await onSubmit(editValues, employee);
         } catch (e) {
            console.log(e);
         }
      } catch (e) {
         console.log(e);
      } finally {
         setIsSaving(false);
      }
   };
   const isSupervisor = useMemo(() => find(roles, {id: getValue('roleId')})?.name === 'supervisor', [getValue, roles]);

   const isNew = !employee?.id;
   const isValid = !errorId && !hasUsernameConflict;

   return (
      <Form onSubmit={handleSubmit} className={classes.formStyle}>
         <Grid item container direction={'column'}>
            <Grid item>
               <Typography className={classes.titleStyle} variant={'h6'} id={'employee.title.label'}/>
            </Grid>

            <Grid item container className={classes.unsizingFlex} spacing={2}>
               <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                     key={'firstName' + defaultValues?.id}
                     name='firstName'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'employee.firstName.label'} noWrap/>}
                     fullWidth
                     required
                     value={getValue('firstName')}
                     onChange={handleChange}
                     disabled={!isEnabled}
                  />
               </Grid>
               <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                     key={'lastName' + defaultValues?.id}
                     name='lastName'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'employee.lastName.label'}/>}
                     fullWidth
                     required
                     value={getValue('lastName')}
                     onChange={handleChange}
                     disabled={!isEnabled}
                  />
               </Grid>
               <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                     key={'roleId' + defaultValues?.id}
                     name='roleId'
                     className={classes.selectStyle}
                     select
                     label='Role'
                     value={getValue('roleId')}
                     fullWidth
                     required
                     disabled={!isEnabled}
                     onChange={handleChange}
                     SelectProps={{
                        MenuProps: {
                           className: classes.menu,
                        },
                     }}
                     margin='dense'
                  >
                     {roles?.map((role) => (
                           <MenuItem key={'role' + role?.id} value={role.id}>{capitalize(role.name)}</MenuItem>
                        )
                     )}
                  </TextField>
               </Grid>
               {isSupervisor && <>
                  <Grid item lg={4} sm={6} xs={12}>
                     <TextField
                        key={'secondarySupervisorId' + defaultValues?.secondarySupervisorId}
                        name='secondarySupervisorId'
                        className={classes.selectStyle}
                        select
                        label='Temporary Supervisor For'
                        defaultValue={defaultValues?.secondarySupervisorId}
                        value={editValues?.secondarySupervisorId}
                        fullWidth
                        disabled={!isEnabled}
                        onChange={handleChange}
                        SelectProps={{
                           MenuProps: {
                              className: classes.menu,
                           },
                        }}
                        margin='dense'
                     >
                        <MenuItem value={null}>None</MenuItem>
                        {supervisors?.map((supervisor) => {
                              if (supervisor?.id !== employee?.id) {
                                 return (
                                    <MenuItem key={'secondary' + supervisor?.id}
                                              value={supervisor?.id}>{supervisor?.firstName} {supervisor?.lastName}</MenuItem>
                                 )
                              }
                              return null
                           }
                        )}
                     </TextField>
                  </Grid>
                  {getValue('secondarySupervisorId') > 0 && (
                     <Grid item lg={4} sm={6} xs={12}>
                        <DatePickerFHG
                           key={'expirationDate' + defaultValues.id}
                           name={'expirationDate'}
                           disablePast
                           disabled={!isEnabled}
                           autoOk
                           label={'Expiration'}
                           onChange={handleChange}
                           value={getValue('expirationDate') || null}
                           fullWidth
                        />
                     </Grid>
                  )}
               </>}
               <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                     key={'supervisorId' + defaultValues?.supervisorId}
                     name='supervisorId'
                     className={classes.selectStyle}
                     select
                     label='Supervisor'
                     defaultValue={defaultValues?.supervisorId}
                     value={editValues?.supervisorId}
                     fullWidth
                     required
                     disabled={!isEnabled}
                     onChange={handleChange}
                     SelectProps={{
                        MenuProps: {
                           className: classes.menu,
                        },
                     }}
                     margin='dense'
                  >
                     <MenuItem value={-1}>None</MenuItem>
                     {supervisors?.map((supervisor) => {
                           if (supervisor?.id !== employee?.id) {
                              return (
                                 <MenuItem key={'supervisor' + supervisor.id}
                                           value={supervisor.id}>{supervisor.firstName} {supervisor.lastName}</MenuItem>
                              )
                           }
                           return null
                        }
                     )}
                  </TextField>
               </Grid>
               <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                     key={'adpId' + defaultValues.id}
                     name='adpId'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'employee.adpId.label'}/>}
                     fullWidth
                     required
                     defaultValue={defaultValues?.adpId}
                     value={editValues?.adpId}
                     onChange={handleChange}
                     disabled={!isEnabled}
                  />
               </Grid>
               <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                     key={'username' + defaultValues.id}
                     name='username'
                     label={<Typography className={classes.inlineStyle} variant='inherit'
                                        id={'employee.username.label'}/>}
                     error={hasUsernameConflict}
                     helperText={hasUsernameConflict ? 'Username is already used.' : undefined}
                     fullWidth
                     required
                     disabled={!isEnabled}
                     defaultValue={defaultValues?.username}
                     value={editValues?.username}
                     onChange={handleUsernameChange}
                     InputProps={{
                        'aria-label': 'Username',
                        endAdornment: (
                           <InputAdornment position='end'>
                              {hasUsernameConflict === false && <CheckCircle color={'primary'}/>}
                           </InputAdornment>
                        )
                     }}
                  />
               </Grid>
               <PasswordTextField
                  key={'password' + defaultValues?.id}
                  name='password'
                  isNew={isNew}
                  disabled={isSaving}
                  onChange={handleChange}
                  password={editValues?.password}
                  confirm={editValues?.confirm}
               />
               <Grid item xs={12} className={classes.flex}>
                  <Grid item className={classes.unsizingFlex}>
                     <Typography variant='caption' className={classes.stickyCaption} id={'required.label'}/>
                  </Grid>
                  <Grid container item className={classes.resizingContainer}>
                     <Grid item>
                        <Button variant='contained' color='primary' type={'submit'}
                                className={classes.button}
                                disabled={!isEnabled || !isValid || !isParentValid || (!isParentChanged && !isChanged)}
                        >
                           <Typography variant='inherit' id={isNew ? 'employee.add.label' : 'employee.update.label'}/>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Form>
   );
}

const GetRoles = gql`
   query getRoles {
      roles:role_All {
         id
         name
      }
   }
`;
const GetSupervisors = gql`
   query getSupervisors {
      supervisors:user_AllSupervisors {
         id
         firstName
         lastName
      }
   }
`;

const CheckUsername = gql`
   query checkUsername ($username: String!) {
      hasUsernameConflict:user_CheckUsername(username: $username)
   }
`;

// export default withStyles(dataStyles)(addQuery({
//    showOnLoad: false,
//    showProgress: false,
//    query: GetRoles,
//    dataKeys: 'roles',
//    errorMessage: 'Cannot get roles.',
//    queries: {
//       checkUsername: { query: CheckUsername, error: 'employee.checkUsername.error' },
//       getSupervisors: { query: GetSupervisors, error: 'employee.getSupervisors.error' },
//    },
// })(injectIntl(withStyles(styles)(EmployeeInfo))));

