import {defer} from 'lodash';
import moment from 'moment';
import {useCallback} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import {PLACEHOLDER_IMAGE_SMALL} from '../Constants';
import useIdleTime from '../fhg/hooks/useIdleTime';
import {MEDIUM_PRIORITY} from '../util/imageUtils';
import {TOP_PRIORITY} from '../util/imageUtils';
import {ImageStorage} from '../util/imageUtils';

export default function useImage(id, showImage, isIdentityLog) {
   const key = `${id}log${isIdentityLog}`;

   const [image, setImage] = useState();
   const [isLoading, setIsLoading] = useState(false);

   const downloadData = useCallback(async (priority, image, isLoading = false) => {

      if (id && !isLoading) {
         try {
            setIsLoading(true);
            ImageStorage.addToQueue(priority, key, id, isIdentityLog).then(image => {
               setImage(image);
               setIsLoading(false);
            });
         } catch (e) {
            console.log(e);
            setImage(undefined);
            setIsLoading(false);
         }
      }
   }, [id, key, isIdentityLog]);

   useIdleTime(() => {
      const queue = ImageStorage.getQueue();
      // When the user is idle, and the queue has more tasks, resume processing the tasks.
      if (!queue.idle()) {
         queue.resume();
      }
   }, () => {
      const queue = ImageStorage.getQueue();

      // When the user is not idle, and the queue has more tasks, pause processing the tasks.
      if (!queue.idle()) {
         queue.pause();
      }
   });

   // The first time check if the image is already stored.
   useEffect(() => {
      const getImage = async () => {
         if (id !== undefined) {
            const imageData = await ImageStorage.getImageData(key);
            const hasImageData = !!imageData?.image;

            setImage(imageData?.image);

            if (!hasImageData && showImage) {
               try {
                  setIsLoading(true);
                  defer(() => {
                     downloadData(TOP_PRIORITY, imageData?.image).then(() => {
                        setIsLoading(false);
                     });
                  });
               } catch (e) {
                  console.log(e);
                  setIsLoading(false);
               }
            } else if (hasImageData) {
               const expirationMillis = moment().subtract(3, 'day').valueOf();

               if (imageData?.createdDate < expirationMillis) {
                  try {
                     setIsLoading(true);
                     defer(() => {
                        downloadData(MEDIUM_PRIORITY, imageData?.image).then(() => {
                           setIsLoading(false);
                        });
                     });
                  } catch (e) {
                     console.log(e);
                     setIsLoading(false);
                  }
               }
            }
         } else if (image) {
            setImage(undefined);
         }
      }
      getImage();
   }, [downloadData, id, image, key, showImage]);

   return {image: image || PLACEHOLDER_IMAGE_SMALL, isLoading};
}
