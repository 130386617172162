import TextField from '@material-ui/core/TextField';
import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';
import {useMemo} from 'react';
import React, {useState, useRef, useEffect} from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {styles as dayStyles} from 'material-ui-pickers/DatePicker/components/Day'
import classnames from 'classnames'
import {DATE_ONLY_FORMAT} from '../../../Constants';
import {sortDate} from '../../utils/Utils';

export const formatDate = (date, format) => {
   let useDate = typeof date === 'number' || typeof date === 'string' ? moment(date) : date;
   return useDate?.format(format || DATE_ONLY_FORMAT);
}


function DateRangePicker({
   classes,
   name,
   value,
   onChange,
   labelFunc,
   format,
   emptyLabel,
   autoOk,
   onClose,
   className,
   InputLabelProps, InputProps,
   entryDays,
   ...props
}) {

   const [begin, setBegin] = useState(value && value[0]);
   const [end, setEnd] = useState(value && value[1]);
   const [hover, setHover] = useState(undefined);
   const picker = useRef();
   const cache = useMemo(() => ((value && value.length > 0 && value.sort(sortDate)) || []), [value]);

   useEffect(() => {
      if (cache.length > 0) {
         setBegin(cache[0]);
         setEnd(cache[1] || cache[0]);
      }
      // eslint-disable-next-line
   }, [cache]);

   function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
      // const dayMillis = day.valueOf();

      const min = Math.min(begin, end || hover || begin);
      const max = Math.max(begin, end || hover || begin);

      return React.cloneElement(dayComponent, {
         onClick: e => {
            e?.preventDefault();
            e?.stopPropagation();
            if (!begin) {
               setBegin(day);
            } else if (!end) {
               setEnd(day);
               if (autoOk) {
                  onChange({target: {name, type: 'date-range', date: [begin, day].sort(sortDate)}});
                  onClose?.()
               }
            } else {
               setBegin(day);
               setEnd(undefined)
            }
         },
         // Only handle hover for devices that are not mobile.
         onMouseEnter: () => !('ontouchstart' in document.documentElement) && setHover(day),
         onMouseLeave: () => !('ontouchstart' in document.documentElement) && setHover(undefined),
         className: classnames(classes.day, {
            [classes.hidden]: dayComponent.props.hidden,
            [classes.current]: dayComponent.props.current,
            [classes.isDisabled]: dayComponent.props.disabled,
            [classes.isSelected]: day >= min && day <= max,
            [classes.selected]: day > min && day < max,
            [classes.beginCap]: max !== min && moment(day).isSame(min, 'day'),
            [classes.endCap]: max !== min && moment(day).isSame(max, 'day'),
            [classes.bothCap]: max === min && moment(day).isSame(min, 'day'),
         })
      });
   }

   return (
      <DatePicker
         {...props}
         value={begin}
         renderDay={renderDay}
         onClose={() => {
            if (begin && !end) {
               setEnd(begin);
               const date = [begin, begin];
               onChange && onChange({target: {name, type: 'date-range', date}}, date, 'date-range');
            }
            onClose && onClose();
         }}
         onDismiss={() => {
            setBegin(cache[0]);
            setEnd(cache[1]);
            onClose && onClose();
         }}
         onChange={() => {
            const date = [begin, end].sort(sortDate);
            onChange && onChange({target: {name, type: 'date-range', date}}, date, 'date-range');
         }}
         onClear={() => {
            setBegin(undefined);
            setEnd(undefined);
            setHover(undefined);
            onChange({target: {name, type: 'date-range', date: []}});
         }}
         ref={picker}
         labelFunc={(date, invalid) => {
            const min = Math.min(begin, end || hover || begin);
            const max = Math.max(begin, end || hover || begin);
            return labelFunc
               ? labelFunc([min, max], invalid)
               : date && min && max
                  ? `${formatDate(min, format)} - ${formatDate(max, format)}`
                  : emptyLabel || ''
         }}
         inputVariant={'standard'}
         TextFieldComponent={TextField}
      />
   )
}

export const styles = theme => {
   const base = dayStyles(theme);
   return {
      ...base,
      day: {
         ...base.day,
         margin: 0,
         width: '40px',
         borderRadius: '0',
      },
      beginCap: {
         opacity: '100%',
         borderRadius: 0,
      },
      endCap: {
         opacity: '100%',
         borderRadius: 0
      },
      bothCap: {
         opacity: '100%',
         borderRadius: 0
      },
      selected: {
         opacity: '70%',
      }
   }
};

export default withStyles(styles, {name: 'DateRangePicker'})(DateRangePicker)
