export const ENDPOINT = !process.env.REACT_APP_ENDPOINT ? '/api/graphql/' : `https://${process.env.REACT_APP_ENDPOINT}/api/graphql/`;
console.log('Endpoint = ', ENDPOINT);
console.log('Version = ', process.env.REACT_APP_VERSION);

// Main logo path.
export const LOGO = '/img/Starkey_Logo_2018.jpg';

export const PLACEHOLDER_IMAGE = '/img/Portrait_Placeholder.png';
export const PLACEHOLDER_IMAGE_SMALL = '/img/placeholder-2.png';
export const CAMERA_SOUND = '/camera.mp3';
export const CAMERA_VOLUME = 0.02;

// Height of the Application bar.
export const APP_BAR_HEIGHT = 80;

// Currently not used. Length of showing undo snackbar.
export const UNDO_DURATION = 4000;

// Clock in will show screen saver after this many millis.
export const SCREEN_SAVER_TIMEOUT = 40000;
export const IDLE_TIMEOUT = 5000;

// Number of images to start employee library for facial recognition.
export const MAX_IMAGES = 10;
export const MIN_IMAGES = 5;

// Delay from the time a photo is taken until the camera is reloaded to take another photo.
export const VIDEO_DELAY = 2000;

export const HOST = 'http://localhost:8081';
// export const HOST = 'https://starkey-test-ksdjafl34itrn.fhgtesting.net';

export const USERNAME_CHECK_INTERVAL = 500;

// Dates displayed to the user.
export const DATE_FORMAT = 'M/D/YYYY hh:mm a';
export const DATE_ONLY_FORMAT = 'M/D/YYYY';
export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';

//Millis to hide the login confirmation.
export const LOGIN_SUCCESS_CONFIRMATION_HIDE = 2000;

export const SERVER_CONNECTION_ERROR = 'ECONNREFUSED';
