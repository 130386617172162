import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import {useCallback} from 'react';
import {useState} from 'react';
import {useMemo} from 'react';
import {useLayoutEffect} from 'react';
import {useEffect} from 'react';
import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {DATE_FORMAT} from '../../Constants';
import Image from '../../fhg/components/Image';
import Typography from '../../fhg/components/Typography';
import {convertTime} from '../../fhg/utils/DataUtil';
import {stringToBoolean, sortDate} from '../../fhg/utils/Utils';
import './LocationTable.css'
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
   table: {
      flex: '1 1',
      maxHeight: '100%',
      width: '100%',
      border: 'none',
      userSelect: 'none',
      overflow: 'auto',
      '& .-sort-desc p::after': {
         content: '"keyboard_arrow_down"',
         direction: 'ltr',
         display: 'inline-block',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         verticalAlign: 'text-top',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
      },
      '& .-sort-asc p::after': {
         content: '"keyboard_arrow_up"',
         direction: 'ltr',
         display: 'inline-block',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         verticalAlign: 'text-top',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
      },
      '& .rt-th, .rt-td': {
         marginTop: 'auto',
         marginBottom: 'auto',
      },
   },
   emptyHeight: {
      height: `calc(100% - ${theme.spacing(4) + 12}px) !important`,
      minHeight: 200,
   },
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      marginTop: 45,
   },
   columnTitle: {

      // padding: '12px 8px',
      color: 'rgb(0, 172, 193)',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 300,
      lineHeight: '1.42857143rem',
      fontSize: 16,
   },
   selected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
         backgroundColor: `${theme.palette.action.selected} !important`,
      },
   },
   imageStyle: {
      objectFit: 'contain',
      width: 80,
      height: 60,
   },
}), {name: 'EmployeeTableStyles'});

/**
 * The list of employees.
 */
export default function EmployeeTable({
   isShowImages = true,
   data,
   search,
   selectedId,
   isLoading = false,
   onDoubleClick,
   onClick,
   disabled
}) {
   const classes = useStyles();
   let blockScroll = false;

   const [filteredData, setFilteredData] = useState([]);
   const [defaultSort, setDefaultSort] = useState(sessionStorage.employeeTable);

   useEffect(() => {
      // Read the table settings from session storage to restore when the user comes back to this page.
      const employeeTableString = sessionStorage.employeeTable;
      const employeeTable = employeeTableString ? JSON.parse(employeeTableString) :
         {sort: [{id: 'Location', desc: false}, {id: 'lastName', desc: false}]};
      setDefaultSort(employeeTable.sort);
   }, []);

   /**
    * When the user changes the sort. Save to the session storage.
    * @param newSorted
    */
   const onSortedChange = (newSorted) => {
      sessionStorage.employeeTable = JSON.stringify({sort: newSorted});
   };

   /**
    * Checks if the row is selected.
    * @param row The row to check.
    * @return {*|boolean} True if the row is selected.
    */
   const isRowSelected = (row) => {
      return row && selectedId === row.original.id;
   };

   /**
    * Scroll the selected row into view.
    */
   const scrollIntoView = useCallback(() => {
      if (blockScroll === false) {
         const elements = document.getElementsByClassName(classes.selected);
         let objDiv = document.getElementsByClassName('rt-tbody')[0];

         if (objDiv && elements.length > 0) {
            objDiv.scrollTop = elements[0].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
               (elements[0].offsetHeight / 2);
         }
      }
   }, [blockScroll, classes.selected]);

   useLayoutEffect(() => {
      scrollIntoView();
   }, [scrollIntoView]);

   /**
    * When the user searches the table.
    * @param search The search text to find.
    * @param columns The table colums. Used for the accessor to get the data from the column to search.
    * @param data The table data.
    * @return {*|Array} The array of filtered rows.
    */
   const onSearch = (search, columns, data) => {
      let filteredData;
      let dateSearch = moment(search);
      const isSearchDate = dateSearch.isValid();
      let isSearchHour = dateSearch.get('hour');
      let isSearchMinute = dateSearch.get('minute');

      if (search) {
         search = stringToBoolean(search).toLocaleLowerCase().trim();
         filteredData = data.filter(item => {
            for (let column of columns) {
               let columnAccessor = column.accessor;
               let value = typeof columnAccessor === 'function' ? columnAccessor(item) : get(item, columnAccessor);

               if (value !== undefined && value !== null && typeof value !== 'object') {
                  const valueMoment = moment(value, DATE_FORMAT, true);
                  // noinspection JSCheckFunctionSignatures
                  if (isSearchDate &&
                     valueMoment.isSame(dateSearch, isSearchMinute ? 'minute' : isSearchHour ? 'hour' : 'day')) {
                     return true;
                  }
                  if (typeof value === 'string') {
                     if (value.toLocaleLowerCase().indexOf(search) >= 0) {
                        return true;
                     }
                  } else if (typeof value === 'number') {
                     if (Number(value) === Number(search)) {
                        return true;
                     }
                  }
               }
            }
            return false;
         });
      } else {
         filteredData = data;
      }

      return filteredData;
   };

   /**
    * Get the columns for the table.
    * @return {*[]} The table columns.
    */
   const columns = useMemo(() => {
      return [
         {
            id: 'image',
            Header: <Typography className={classes.columnTitle} id={'employees.photo.column'}/>,
            accessor: row => {
               const id = get(row, 'id');
               return (
                  <Image key={id} id={id} alt='Profile' className={classes.imageStyle} showImage={isShowImages}/>
               )
            },
            sortable: false,
            minWidth: 150,
         }, {
            id: 'firstName',
            Header: <Typography className={classes.columnTitle} id={'employees.firstName.column'}/>,
            accessor: 'firstName',
            minWidth: 150,
         }, {
            id: 'lastName',
            Header: <Typography className={classes.columnTitle} id={'employees.lastName.column'}/>,
            accessor: 'lastName',
            minWidth: 150,
         }, {
            id: 'Location',
            Header: <Typography className={classes.columnTitle} id={'employees.location.column'}/>,
            accessor: row => {
               return get(row, 'lastTwoClocks[1].location.name', 'N/A');
            },
            minWidth: 150,
         }, {
            id: 'clockIn',
            Header: <Typography className={classes.columnTitle} id={'employees.clockedIn.column'}/>,
            accessor: row => {
               return convertTime(row, 'lastTwoClocks[0].loggedTime');
            },
            minWidth: 150,
            sortMethod: sortDate
         }, {
            id: 'clockOut',
            Header: <Typography className={classes.columnTitle} id={'employees.clockedOut.column'}/>,
            accessor: row => {
               return convertTime(row, 'lastTwoClocks[1].loggedTime');
            },
            minWidth: 150,
            sortMethod: sortDate
         },
      ];
   }, [classes.columnTitle, classes.imageStyle, isShowImages]);

   useEffect(() => {
      const filteredData = onSearch(search, columns, data);
      setFilteredData(filteredData);
   }, [search, data, columns]);

   if (!data || data?.length <= 0) {
      return null;
   }
   return (
      <ReactTable key={'employeeTable' + isShowImages} defaultPageSize={10000} minRows={1} loading={isLoading}
                  LoadingComponent={() => isLoading &&
                     <Grid container alignItems={'center'}> <CircularProgress className={classes.progress}/></Grid>}
                  multiSort={false} className={classNames(classes.table, '-highlight',
         {[classes.emptyHeight]: !filteredData || filteredData.length <= 0})}
                  data={filteredData}
                  defaultSorted={defaultSort}
                  onSortedChange={onSortedChange}
                  showPagination={false}
                  getTrProps={(state, rowInfo) => {
                     return {
                        className: isRowSelected(rowInfo) ? classes.selected : undefined,
                        onClick: (e) => {
                           if (!disabled) {
                              e.preventDefault();
                              blockScroll = true;
                              onClick?.(rowInfo.original);
                           }
                        },
                        onDoubleClick: (e) => {
                           if (!disabled) {
                              e.preventDefault();
                              blockScroll = true;

                              onClick?.(rowInfo.original.id);
                              onDoubleClick?.(rowInfo.original);
                           }
                        },
                     }
                  }}

                  columns={columns}
      />
   );
}
