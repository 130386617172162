import { Dialog, } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

function getRandomInt(max) {
   return Math.floor(Math.random() * (max + 1));
}

/**
 * Confirmation dialog that shows the user a message and allows for confirmation or cancel.
 */
export default class ScreenSaver extends Component {

   static propTypes = {
      open: PropTypes.bool,            // Indicates if the dialog should be open or not.
      image: PropTypes.string,         // Title for the confirmation dialog.
      timeout: PropTypes.number,         // Title for the confirmation dialog.
      isEnabled: PropTypes.bool,
   };

   static defaultProps = {
      open: false,
      timeout: 4000,
      isEnabled: true,
   };

   state = {
      open: false,
   };

   componentWillReceiveProps(nextProps) {
      if(nextProps.isEnabled !== this.props.isEnabled) {
         if (nextProps.isEnabled) {
            this.restartTimer();
         } else {
            if (this.interval) {
               clearInterval(this.interval);
               this.interval = undefined;
            }
            if (this.timer) {
               clearTimeout(this.timer);
               this.timer = undefined;
            }
            this.setState({open: false});
         }
      }
   }

   componentDidMount() {
      document.addEventListener("mousemove", this.restartTimer);
   }

   componentWillUnmount() {
      document.removeEventListener('mousemove', this.restartTimer);

      if (this.interval) {
         clearInterval(this.interval);
      }
      if (this.timer) {
         clearTimeout(this.timer);
      }
   }

   restartTimer = () => {
      const { isEnabled } = this.props;

      if (isEnabled) {
         if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
         }
         if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
         }

         if (this.props.onWakeUp && this.state.open) {
            this.props.onWakeUp();
         }
         this.setState({ open: false });

         this.timer = setTimeout(() => {
            if (this.props.onTimeout && !this.state.open) {
               this.props.onTimeout();
            }
            this.setState({ open: true });
            this.interval = setInterval(() => {
               this.setState({
                  marginLeft: getRandomInt(window.innerWidth - 460),
                  marginTop: getRandomInt(window.innerHeight - 320)
               })
            }, 3000);
         }, this.props.timeout)
      }
   };

   render() {
      const { open, children, isEnabled } = this.props;
      const { marginLeft, marginTop } = this.state;

      return (
         <Dialog
            maxWidth='xs'
            aria-labelledby='confirmation-dialog-title'
            open={(open || this.state.open) && isEnabled }
            style={{marginLeft, marginTop, height: 320, width: 460, alignItems: 'normal', justifyContent: 'normal'}}
         >
            <div>
               {children}
            </div>
         </Dialog>
      );
   }
}
