import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Typography from '../../fhg/components/Typography';

const styles = theme => ({
   snackbarRoot: {
      backgroundColor: '#E3EEF0',
   },
   message: {
      width: '100%',
   },
   rejectStyle: {
      backgroundColor: theme.palette.error.main,
      color: 'white',
   },
   close: {
      position: 'absolute',
      top: 0,
      right: 0,
   },
   images: {
      '&:last-child': {
         marginLeft: theme.spacing(1),
      },
      width: 100,
      height: 80,
   },
});

// Component to slice the snackbar up.
function TransitionUp(props) {
   return <Slide direction='up' {...props} />;
}

/**
 * Component to show the confirmation Snackbar to the user
 */
class ConfirmMatchSnackbar extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // Styles for the component.
      open: PropTypes.bool,                     // Indicates if the Error snackbar should be shown to the user.
      onConfirm: PropTypes.func,                // Callback when the employee confirms identity.
      onReject: PropTypes.func,                 // Callback when the employee rejects identity.
      employee: PropTypes.object,               // The employee being confirmed.
   };


   render() {
      const {classes, open, onConfirm, onReject, employee = {}} = this.props;

      //Add 1/2 second to the hide to make sure that it gets hidden.
      return (
         <Snackbar
            open={open}
            autoHideDuration={null}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            transition={TransitionUp}
            ContentProps={{
               'aria-describedby': 'message-id',
               classes: {
                  root: classes.snackbarRoot,
                  message: classes.message,
               }
            }}
            message={(
               <Grid container direction={'column'} alignItems={'center'}>
                  <Grid item>
                     <Typography variant={'h5'} id={'confirm.message'}
                                 values={{firstName: employee.firstName, lastName: employee.lastName}}
                                 color={'primary'}/>
                  </Grid>
                  <Grid container direction={'row '} spacing={1} justifyContent={'center'}>
                     <Grid item>
                        <Button key='yesButton' variant='contained' color='primary' size='small' onClick={onConfirm}>
                           <Typography color='inherit'>Yes</Typography>
                        </Button>
                     </Grid>
                     <Grid item>
                        <Button key='noButton' variant='contained' className={classes.rejectStyle} size='small'
                                onClick={onReject}>
                           <Typography color='inherit'>No</Typography>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            )}
         />
      );
   }
}

export default withStyles(styles)(ConfirmMatchSnackbar);
