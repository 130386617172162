import {lighten} from '@material-ui/core';
import {useTheme} from '@material-ui/core';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import GridFHG from '../../fhg/components/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/AddLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditLocation';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useState} from 'react';
import React from 'react';
import {UPDATE_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import {DELETE_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import {ADD_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {cacheAdd} from '../../fhg/utils/DataUtil';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import {changedProperties} from '../../fhg/utils/Utils';
import LocationTable from './LocationTable';
import EditLocation from './EditLocation';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
   outerGridStyle: {
      backgroundColor: '#EBEBEB',
   },
   innerGridStyle: {
      paddingTop: theme.spacing(1),
   },
   tableFrameStyle: {
      maxWidth: 800,
      margin: 12,
      overflow: 'auto',
      display: 'flex',
      maxHeight: 'calc(100% - 70px)',
   },
   buttonStyle: {
      margin: theme.spacing(1),
      '&:hover': {
         color: theme.palette.error.main,
      }
   },
   deleteColorStyle: {
      backgroundColor: lighten(theme.palette.error.light, 0.7),
      color: 'black',
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}), {name: 'LocationsStyles'});

/**
 * The component to show the list of locations.
 */
export default function Locations() {
   const classes = useStyles();
   const theme = useTheme();

   const [location, setLocation] = useState({id: -1});
   const [selectedId, setSelectedId] = useState();
   const [defaultId, setDefaultId] = useState();
   const [isEditOpen, setIsEditOpen] = useState(false);

   const [{locations = [], defaultLocation = {}}] = useQueryFHG(GetLocations);

   const [addLocation, {data: addLocationData}] = useMutationFHG(AddLocation, undefined, undefined,
      'location.add.error');
   const [updateLocation, {data: updateLocationData}] = useMutationFHG(UpdateLocation, undefined, undefined,
      'location.update.error');
   const [deleteLocation] = useMutationFHG(DeleteLocation, undefined, undefined, 'location.delete.error');
   const [setDefaultLocation] = useMutationFHG(SetDefaultLocation, undefined, undefined, 'location.update.error');

   useEffect(() => {
      if (defaultLocation) {
         setDefaultId(defaultLocation.id);
      }
   }, [defaultLocation]);

   useEffect(() => {
      if (updateLocationData) {
         setSelectedId(updateLocationData.location.id);
      }
   }, [updateLocationData]);

   useEffect(() => {
      if (addLocationData) {
         setSelectedId(addLocationData.location.id);
      }
   }, [addLocationData]);

   /**
    * When the user selects to add a location.
    */
   const onAdd = () => {
      setIsEditOpen(true);
      setLocation(undefined);
      setSelectedId(undefined);
   };

   /**
    * When the user double clicks a row to select a location to edit.
    * @param location The location to edit.
    */
   const onEdit = (location) => {
      setIsEditOpen(true);
      setLocation(location);
      selectedId(location?.id);
   };

   /**
    * When the user clicks a button to edit the current selected location.
    */
   const onEditClick = () => {
      const selectedIndex = locations.findIndex(item => item.id === selectedId);

      if (selectedIndex >= 0 && locations[selectedIndex]) {
         setIsEditOpen(true);
         setLocation(locations[selectedIndex]);
      }
   };

   /**
    * When the user closes the edit dialog.
    */
   const onClose = () => {
      setIsEditOpen(false);
   };

   const getLocationsCacheQueries = () => {
      return [{query: GetLocations, undefined, queryPath: 'locations'}];
   }

   /**
    * When the user submits the changes for edit.
    * @param location   The location with changes.
    * @return {Promise<void>}
    */
   const onEditSubmit = async (location) => {

      if (location.id >= 0) {
         const selectedIndex = locations.findIndex(item => item.id === selectedId);
         const changedObject = changedProperties(location, locations[selectedIndex]);
         updateLocation({
            variables: changedObject,
         });
      } else {
         const variables = location;
         addLocation({
            variables,
            optimisticResponse: {
               __typename: 'Mutation',
               location: {
                  __typename: 'CostCenter',
                  ...variables,
               }
            },
            update: cacheAdd(getLocationsCacheQueries(), 'location'),
         });
      }
      setIsEditOpen(false);
   };

   /**
    * When the user selected a row in the table.
    * @param selectedId The selected row ID.
    * @param location The selected location.
    */
   const onSelect = (selectedId, location) => {
      setSelectedId(selectedId);
      setLocation(location);
   };

   /**
    * When the user selects to delete a location.
    * @return {Promise<void>}
    */
   const onDelete = async () => {
      const selectedIndex = locations.findIndex(item => item.id === selectedId);
      if (selectedIndex >= 0 && locations[selectedIndex]) {
         await deleteLocation({
            variables: {id: selectedId},
            optimisticResponse: {costCenter_Delete: 1},
            update: cacheDelete(getLocationsCacheQueries(), selectedId),
         });
      } else {
         console.log('Could not find location.');
      }
      setSelectedId(undefined);
   };

   /**
    * When the user changes the default location.
    * @param newDefault The new default location for this installation.
    * @return {Promise<void>}
    */
   const onDefaultChange = async (newDefault) => {
      const defaultId = Number.parseInt(newDefault, 10);

      try {
         await setDefaultLocation({variables: {id: defaultId}});
         setDefaultId(defaultId);
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <GridFHG container className={classes.outerGridStyle} fullHeight direction={'column'}>
         <EditLocation key={location && location.id} open={isEditOpen} onSubmit={onEditSubmit}
                       onClose={onClose} location={location}/>
         <GridFHG item className={classes.innerGridStyle} resizable={false} fullWidth>
            <Button color={'primary'} variant={'text'} onClick={onAdd}>
               <AddIcon color={'inherit'}/>
               Add Location
            </Button>
            <Button color={'primary'} variant={'text'} onClick={onEditClick} disabled={!selectedId}>
               <EditIcon color='inherit'/>
               Edit
            </Button>
            <ConfirmButton
               className={classes.buttonStyle}
               color={'primary'}
               onConfirm={onDelete}
               messageKey={'location.delete.confirm'}
               values={{name: location?.name || 'N/A', type: 'Location'}}
               size='large'
               submitStyle={classes.deleteColorStyle}
               startIcon={<DeleteIcon/>}
               buttonTypographyProps={{variant: 'inherit'}}
               disabled={!selectedId || selectedId === defaultId}
            />
         </GridFHG>
         <GridFHG item resizable>
            <Paper elevation={0} className={classes.tableFrameStyle}>
               <LocationTable data={locations} onDoubleClick={onEdit} onClick={onSelect}
                              defaultLocation={defaultId}
                              selectedId={selectedId} onDefaultChange={onDefaultChange}/>
            </Paper>
         </GridFHG>
      </GridFHG>
   );
}

Locations.fragments = {
   entry: gql`
      fragment LocationInfo on CostCenter {
         id
         adpId
         name
      }
   `,
};

const GetLocations = gql`
   query getLocations {
      locations:costCenter_All {
         ...LocationInfo
      }
      defaultLocation:costCenter_GetDefault {
         ...LocationInfo
      }
   }
   ${Locations.fragments.entry}
`;

const AddLocation = {
   mutation: gql`
      mutation addLocation($name: String!, $adpId: String!) {
         location:costCenter_Create(costCenter: {name: $name, adpId: $adpId}) {
            ...LocationInfo
         }
      }
      ${Locations.fragments.entry}
   `,
   typeKey: 'location.type',
   actionKey: ADD_ACTION,
};

const UpdateLocation = {
   mutation: gql`
      mutation updateLocation($id: Int!, $name: String, $adpId: String) {
         location:costCenter_Update(costCenterId: $id, costCenter: {name: $name, adpId: $adpId}) {
            ...LocationInfo
         }
      }
      ${Locations.fragments.entry}
   `,
   typeKey: 'location.type',
   actionKey: UPDATE_ACTION,
};

const SetDefaultLocation = {
   mutation: gql`
      mutation setDefaultLocation($id: Int!) {
         defaultLocation:costCenter_SetDefault(costCenterId: $id )
      }
   `,
   typeKey: 'location.type',
   actionKey: UPDATE_ACTION,
};

const DeleteLocation = {
   mutation: gql`
      mutation deleteLocation ($id: Int!) {
         costCenter_Delete(costCenterId: $id)
      }
   `,
   typeKey: 'location.type',
   actionKey: DELETE_ACTION,
};

Locations.propTypes = {
   data: PropTypes.array,
};
