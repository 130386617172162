import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {LOGO} from './Constants';
import Login from './fhg/components/security/Login';

const styles = theme => ({

   root: {
      width: '100%',
   },
   appBar: {
      backgroundColor: '#EBEBEB',
   },
   formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
   },
   cardTitle: {
      color: '#FFFFFF',
      marginTop: '0',
      marginBottom: '5px',
      fontSize: '1.125em'
   },
});

class StarkeyLogin extends Component {
   static propTypes = {
      locations: PropTypes.array,
   };

   state = {
      location: undefined,
   };

   render() {
      const {classes} = this.props;

      return (
         <Login clasName={classes.root} logo={LOGO} title={'Login'} isDialog={false} isOpen={true} showForgot={false}
                successPath={'/admin'}/>
      );
   }
}

export default withRouter(withStyles(styles)(StarkeyLogin));
