import MomentUtils from '@date-io/moment';
import {Fab} from '@material-ui/core';
import Hidden from '@material-ui/core//Hidden';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import TimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import Person from '@material-ui/icons/Person';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Place from '@material-ui/icons/Place';
import Search from '@material-ui/icons/Search';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useRef} from 'react';
import React from 'react';
import {Switch, Route, Link, Redirect} from 'react-router-dom'
import Employee from './components/admin/Employee';
import Employees from './components/admin/Employees';
import Locations from './components/admin/Locations';
import SimpleAppBar from './components/SimpleAppBar';
import ClockInAudit from './components/supervisor/ClockInAudit';
import {LOGO} from './Constants';
import ErrorSnackbar from './fhg/components/ErrorSnackbar';
import Loading from './fhg/components/Loading';
import Login from './fhg/components/security/Login';
import Typography from './fhg/components/Typography';
import useLazyQueryFHG from './fhg/hooks/data/useLazyQueryFHG';
import {useLocation} from 'react-router-dom';

export const URI =
   process.env.NODE_ENV === 'production'
      ? ''
      : `https://${process.env.REACT_APP_ENDPOINT}`;

const useStyles = makeStyles(theme => ({
   root: {
      width: '100%',
   },
   appBar: {
      marginBottom: 1,
      color: '#FFFFFF',
      boxShadow: '0 2px 20px 0px rgba(0, 0, 0, 0.14), 0 4px 12px -5px #108397',
      // backgroundColor: '#108397',
      // padding: '2px 0',
   },
   search: {
      [theme.breakpoints.down('md')]: {
         width: '100$',
      },
   },
   searchButton: {
      width: 40,
      height: 40,
      background: 'white',
      color: 'gray',
   },
   whiteInput: {
      color: 'inherit',
   },
   whiteUnderline: {
      '&:hover:not($disabled):before,&:before': {
         borderColor: 'inherit !important'
      },
      '&:after': {
         borderColor: 'inherit !important'
      }
   },
   buttonLink: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
         backgroundColor: 'rgba(0, 0, 0, 0.08)',
      }
   },
   otherGrid: {
      // marginTop: 10,
      alignItems: 'baseline',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
         flexDirection: 'column',
         padding: theme.spacing(1),
      },
   },
}), {name: 'MainStyles'});

export default function Main() {
   const classes = useStyles();
   const location = useLocation();

   const [search, setSearch] = useState('');
   const [currentSearch, setCurrentSearch] = useState();

   const [errorMessageId, setErrorMessageId] = useState();

   //TODO after test remove true for admin and supervisor.
   const [isUserAdmin, setIsUserAdmin] = useState();
   const [isUserSupervisor, setIsUserSupervisor] = useState();

   const [anchorEl, setAnchorEl] = useState();
   const [showClose, setShowClose] = useState(false);

   const [loadPrivileges, {data: loadPrivilegesData, error: loadPrivilegesError}] = useLazyQueryFHG(IsPrivileged, {errorPolicy: 'all'});

   useEffect(() => {
      if (loadPrivilegesError) {
            setIsUserAdmin(false);
            setIsUserSupervisor(false);
      }
   }, [loadPrivilegesError]);

   useEffect(() => {
       loadPrivileges();
   }, [loadPrivileges]);

   const onSearchChange = ({target}) => {
      setSearch(target.value);
      handleSearchChange(target.value);
   };

   const onKeydown = (event) => {
      if (event.key === 'Escape') {
         event.stopPropagation();
         event.preventDefault();
         onCancelSearch();
      }
   };

   const onCancelSearch = () => {
      handleSearchChange('');
   };

   const handleLogout = async () => {
      try {
         await fetch(`${URI}/auth/logout`, {
            credentials: 'include',
            method: 'POST',
            headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
            }
         });
         setIsUserAdmin(false);
         setIsUserSupervisor(false);
         setAnchorEl(null);
         window.location.reload();
      } catch (error) {
         console.log(error);
      }
   };

   const handleUserMenuClick = event => {
      setAnchorEl(event.currentTarget);
   };

   const handleUserMenuClose = () => {
      setAnchorEl(null);
   };

   useEffect(() => {
      if (loadPrivilegesData) {
         setIsUserSupervisor(loadPrivilegesData.isUserSupervisor);
         setIsUserAdmin(loadPrivilegesData.isAdmin);

         if (!loadPrivilegesData.isSupervisor && !loadPrivilegesData.isAdmin) {
            setErrorMessageId('employees.notAdmin.error');
         } else {
            setErrorMessageId(undefined);
         }
      }
   }, [loadPrivilegesData]);

   const onLoginSuccess = async () => {
      loadPrivileges();
   };

   const handleSearch = useCallback((search) => {
      setCurrentSearch(search);
   }, []);

   const handleSearchChange = useRef(debounce(handleSearch, 500)).current;

   const onErrorClose = () => {
      setErrorMessageId(undefined);
   };

   const menuOpen = Boolean(anchorEl);

   if (isUserAdmin === undefined && isUserSupervisor === undefined && !loadPrivilegesError) {
      return <Loading isLoading/>
   }
   if (!isUserAdmin && !isUserSupervisor) {
      return (
         <div>
            <ErrorSnackbar open={errorMessageId !== undefined} errorId={errorMessageId} onClose={onErrorClose}
                           enableRefresh={false}/>
            <Login className={classes.root} logo={LOGO} title={'Login'} isDialog={false} isOpen={true}
                   successPath={location.pathname} onSuccess={onLoginSuccess} showForgot={false}/>
         </div>
      );
   }
   return (
      <Grid container wrap={'nowrap'} direction={'column'}
            style={{height: '100vh', overflow: 'hidden', backgroundColor: 'rgb(235, 235, 235)'}}>
         <SimpleAppBar
            classes={{appBar: classes.appBar}}
            logo={<Grid container style={{width: 'auto'}} alignItems={'center'} spacing={1}><Grid item><img
               alt='Logo' style={{height: 50, width: 75}} src={LOGO}/></Grid><Grid item>Employee
               Administration</Grid></Grid>}
            color={'primary'}
         >
            <Grid item>
               <Button href='/clock' color='inherit'>
                  <TimeIcon/>Show Clock In/Out
               </Button>
               {isUserAdmin && (
                  <React.Fragment>
                     <Button component={Link} to='/admin/employees'
                             style={{display: location.pathname === '/admin/employees' ? 'none' : undefined}}
                             color='inherit'>
                        <Person/>Employees
                     </Button>
                     <Button component={Link} to='/admin/locations'
                             style={{display: location.pathname === '/admin/locations' ? 'none' : undefined}}
                             color='inherit'>
                        <Place/>Locations
                     </Button>
                     <Button component={Link} to='/supervisor/audit'
                             style={{display: location.pathname === '/supervisor/audit' ? 'none' : undefined}}
                             color='inherit'>
                        <AssignmentInd/>Audit
                     </Button>
                  </React.Fragment>
               )}
            </Grid>
            <Grid item style={{flexDirection: 'row'}}>
               <Grid container className={classes.otherGrid} wrap={'nowrap'}>
                  <Route exact path='/admin/employees' render={() => (
                     <Grid container direction={'row'} style={{width: 'auto'}}>
                        <TextField
                           className={classes.search}
                           onChange={onSearchChange}
                           placeholder={'Search'}
                           value={search}
                           onKeyDown={onKeydown}
                           InputProps={{
                              'aria-label': 'Search',
                              onFocus: () => setShowClose(true),
                              onBlur: () => setShowClose(false),
                              endAdornment: (
                                 <InputAdornment position='end'>
                                    {showClose ? <CloseIcon onMouseDown={onCancelSearch}/> :
                                       <Search/>
                                    }
                                 </InputAdornment>
                              ),
                              classes: {
                                 root: classes.whiteInput,
                                 underline: classes.whiteUnderline,
                              },
                           }}
                        />
                     </Grid>
                  )}/>
                  <Grid item>
                     <Fab
                        color={'inherit'}
                        aria-label='Person'
                        className={classes.buttonLink}
                        onClick={handleUserMenuClick}
                     >
                        <Person/>
                        <Hidden mdUp>
                           <p>Profile</p>
                        </Hidden>
                     </Fab>
                     <Menu
                        id='menu-appbar'
                        anchorEl={anchorEl}
                        anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={menuOpen}
                        onClose={handleUserMenuClose}
                     >
                        <MenuItem onClick={handleLogout}>
                           <Typography id='path.logout' variant='menuItem'/>
                        </MenuItem>
                     </Menu>
                  </Grid>
               </Grid>
            </Grid>
         </SimpleAppBar>
         <Switch>
            {isUserAdmin && (
               <MuiPickersUtilsProvider utils={MomentUtils}>
                  <React.Fragment>
                     <Route exact path='/admin/employees' render={() => <Employees search={currentSearch}/>}/>
                     <Route exact path='/admin/employee/:id?' component={Employee}/>
                     <Route exact path='/admin/locations' component={Locations}/>
                     <Route exact path='/supervisor/audit' component={ClockInAudit}/>
                  </React.Fragment>
               </MuiPickersUtilsProvider>
            )}
            {(isUserSupervisor || isUserAdmin) && (
               <Route exact path='/supervisor/audit' component={ClockInAudit}/>
            )}
            {isUserSupervisor && location.pathname === '/admin' && (
               <Route path='/*' render={() => <Redirect to='/supervisor/audit'/>}/>
            )}
         </Switch>
      </Grid>
   )
}

const IsPrivileged = gql`
   query {
      isAdmin:user_AmIAdmin
      isUserSupervisor:user_AmISupervisor
   }
`;
