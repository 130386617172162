import {Grid} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import Fade from '@material-ui/core/Fade/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';
import {map} from 'lodash';
import {debounce} from 'lodash';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import moment from 'moment';
import {useCallback} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useRef} from 'react';
import React from 'react';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import {formatDate} from '../../fhg/components/edit/DateRangePicker';
import DateRangePicker from '../../fhg/components/edit/DateRangePicker';
import GridFHG from '../../fhg/components/Grid';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import {SET_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import {toNumber} from '../../fhg/utils/Utils';
import {ImageStorage} from '../../util/imageUtils';
import ClockInAuditTable from './ClockInAuditTable';

const TODAY_RANGE = 0;
const WEEK_RANGE = 1;
const MONTH_RANGE = 2;
const SIXTY_DAY_RANGE = 3;
const NINETY_DAY_RANGE = 4;
const CUSTOM_DAY_RANGE = 5;

const useStyles = makeStyles(theme => ({
   outerGridStyle: {
      paddingBottom: 10,
      backgroundColor: '#EBEBEB',
   },
   innerGridStyle: {
      paddingTop: theme.spacing(1),
      height: '100%'
   },
   tableFrameStyle: {
      margin: 12,
      overflow: 'auto',
      display: 'flex',
      maxHeight: 'calc(100% - 78px)',
   },
   selectStyle: {
      minWidth: 150,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
   },
   confidenceStyle: {
      margin: theme.spacing(2, 2, 0),
      width: 200,
   },
   savedStyle: {
      color: green[300],
      position: 'absolute',
      zIndex: 1001,
      width: '100%',
      textAlign: 'right',
      right: 16
   },
}), {name: 'ClockInAuditStyles'});

/**
 * The list of all clock in and clock out punches for a given time period..
 */
export default function ClockInAudit() {
   const classes = useStyles();

   const [isShowPhotos, setIsShowPhotos] = useState(localStorage.showAuditPhotos === 'true');
   const [isPasswordOnly, setIsPasswordOnly] = useState(localStorage.passwordOnlyAudit === 'true');
   const [isShowDirectReports, setIsShowDirectReports] = useState(localStorage.showDirectReportsAudit === 'true');
   const [isOpen, setIsOpen] = useState(false);

   const [dateTime, setDateTime] = useState(toNumber(localStorage.auditDateTime) || WEEK_RANGE);
   const [minDateTime, setMinDateTime] = useState();
   const [maxDateTime, setMaxDateTime] = useState();

   const getInitialDates = () => {
      if (localStorage.auditDates) {
         const dates = JSON.parse(localStorage.auditDates);
         return map(dates, date => moment(date));
      }
      return [];
   };
   const [daySelected, setDaySelected] = useState(getInitialDates());

   /**
    * Get the date range for the current filter setting.
    *
    * @return {{minDateTime: string, maxDateTime: string}}
    */
   const getDateRange = useCallback(() => {
      let minDateTime;
      let maxDateTime = moment().endOf('day').toISOString();

      switch (dateTime) {
         default:
            console.log('Bad date range option - ' + dateTime + '. Using Today.');
         //Intentional Fallthrough
         case TODAY_RANGE:
            minDateTime = moment().startOf('day').toISOString();
            break;
         case WEEK_RANGE:
            minDateTime = moment().subtract(7, 'days').startOf('day').toISOString();
            break;
         case MONTH_RANGE:
            minDateTime = moment().subtract(30, 'days').startOf('day').toISOString();
            break;
         case SIXTY_DAY_RANGE:
            minDateTime = moment().subtract(60, 'days').startOf('day').toISOString();
            break;
         case NINETY_DAY_RANGE:
            minDateTime = moment().subtract(90, 'days').startOf('day').toISOString();
            break;
         case CUSTOM_DAY_RANGE:
            if (daySelected?.length > 0) {
               minDateTime = daySelected?.[0].startOf('day').toISOString();
               maxDateTime = daySelected?.[1].endOf('day').toISOString();
            }
            break;
      }
      return {minDateTime, maxDateTime};
   }, [dateTime, daySelected]);

   useEffect(() => {
      if (dateTime) {
         const {minDateTime, maxDateTime} = getDateRange();
         setMinDateTime(minDateTime);
         setMaxDateTime(maxDateTime);
      }
   }, [dateTime, getDateRange]);

   const [setConfidenceLevel] = useMutationFHG(SetConfidenceLevel, undefined, true);
   const [confidenceLevel, setConfidenceLevelLocal] = useState();

   const [confidence, setConfidence] = useState();
   const [isSaved, setIsSaved] = useState(false);

   useEffect(() => {
      if (confidenceLevel) {
         setConfidence(confidenceLevel);
      }
   }, [confidenceLevel]);

   const saveEdit = useRef(debounce(async () => {
      try {
         const result = document.forms['myform'].reportValidity();
         if (result && confidence) {
            setConfidenceLevel({variables: {confidenceLevel: Number(confidence)}});
            setIsSaved(true);
            setTimeout(() => {
               setIsSaved(false);
            }, 2000);
         }
      } catch (error) {/*intentionally blank*/}
   }, 3000)).current;

   useEffect(() => {
      return () => {
         saveEdit.flush();
      }
   }, [saveEdit]);

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The change password event target.
    */
   const onChange = ({target}) => {
      setDateTime(target.value);
      localStorage.auditDateTime = target.value;
   };

   /**
    * When input information is changed, puts the changes in the state.
    *
    * @param target The changed target from the event.
    */
   const onEditChange = ({target}) => {
      setIsSaved(false);
      setConfidence(target?.value);
      saveEdit();
   };

   const handleChange = event => {
      setIsShowPhotos(event.target.checked);
      localStorage.showAuditPhotos = event.target.checked;
      if (!event.target.checked) {
         ImageStorage.clear();
      }
   };

   const handleShowDirectReportsChange = event => {
      setIsShowDirectReports(event.target.checked);
      localStorage.showDirectReportsAudit = event.target.checked;
   };

   const handlePasswordOnlyChange = event => {
      setIsPasswordOnly(event.target.checked);
      localStorage.passwordOnlyAudit = event.target.checked;
   };

   const areDatesValid = dates => {
      const checkDates = compact(castArray(dates));

      //There need to be 1 or 2 dates, 0 or less and greater than 2 dates are not valid.
      if (checkDates.length <= 0 || checkDates.length > 2) {
         return false;
      }
      for (const date of checkDates) {
         if (date) {
            if (!date.isValid || !date.isValid()) {
               return false;
            }
         }
      }
      return true;
   }
   const handleDateChange = async (event, dates) => {
      if (areDatesValid(dates)) {
         setDaySelected(dates);
         localStorage.auditDates = JSON.stringify(dates);
      }
   };

   function handleOpen() {
      setDaySelected([]);
      setIsOpen(true);
   }

   const handleDataLoad = (auditEntriesData) => {
      setConfidenceLevelLocal(auditEntriesData?.confidenceLevel);
   };

   return (
      <GridFHG container className={classes.outerGridStyle} fullWidth>
         <GridFHG item className={classes.innerGridStyle} fullWidth>
            <GridFHG container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
               <GridFHG container item direction={'row'} spacing={2} alignItems={'center'} resizable>
                  <GridFHG item resizable={false}>
                     <TextField
                        name='dateTime'
                        className={classes.selectStyle}
                        select
                        label='Filter by Date'
                        value={dateTime}
                        onChange={onChange}
                        fullWidth={false}
                     >
                        <MenuItem value={TODAY_RANGE}>Today</MenuItem>
                        <MenuItem value={WEEK_RANGE}>7 Days</MenuItem>
                        <MenuItem value={MONTH_RANGE}>30 Days</MenuItem>
                        <MenuItem value={SIXTY_DAY_RANGE}>60 Days</MenuItem>
                        <MenuItem value={NINETY_DAY_RANGE}>90 Days</MenuItem>
                        <MenuItem value={CUSTOM_DAY_RANGE} onClick={() => handleOpen()}>
                           {!isOpen && !daySelected?.length > 0 ? 'Other...' : !isOpen ? `${formatDate(daySelected?.[0], '')} - ${formatDate(daySelected?.[1])}` : null}
                        </MenuItem>
                     </TextField>
                  </GridFHG>
                  {isOpen && (
                     <DateRangePicker
                        name={'date'}
                        style={{display: 'none'}}
                        open={isOpen}
                        labelKey={'audit.customDate.label'}
                        value={daySelected}
                        autoOk={false}
                        disableFuture={true}
                        onChange={handleDateChange}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        autoFocus
                        required
                     />
                  )}
                  <GridFHG item>
                     <CheckboxFHG
                        key={'showDirectReports'}
                        name={'showDirectReports'}
                        onChange={handleShowDirectReportsChange}
                        labelKey={'employees.showDirectReports.label'}
                        value={'showDirectReports'}
                        checked={isShowDirectReports}
                        marginTop={0}
                     />
                  </GridFHG>
                  <GridFHG item resizable={false}>
                     <CheckboxFHG
                        key={'Password Only'}
                        name={'passwordOnly'}
                        onChange={handlePasswordOnlyChange}
                        labelKey={'audit.passwordOnly.label'}
                        value={'passwordOnly'}
                        checked={isPasswordOnly}
                        fullWidth={false}
                        marginTop={0}
                     />
                  </GridFHG>
                  <GridFHG item resizable={false}>
                     <CheckboxFHG
                        key={'showPhotos'}
                        name={'showPhotos'}
                        onChange={handleChange}
                        labelKey={'employees.showPhoto.label'}
                        value={'showPhotos'}
                        checked={isShowPhotos}
                        fullWidth={false}
                        marginTop={0}
                     />
                  </GridFHG>
               </GridFHG>
               <Grid item>
                  <form id={'myform'}>
                     <TextField
                        name='confidenceLevel'
                        type='number'
                        className={classes.confidenceStyle}
                        label='Set Confidence'
                        value={confidenceLevel || ''}
                        onChange={onEditChange}
                        inputProps={{min: 60, max: 90, title: 'Number between 60 - 90.'}}
                        // eslint-disable-next-line
                        InputProps={{
                           startAdornment: isSaved &&
                              <Fade className={classes.savedStyle} in={isSaved} timeout={750}><span>Saved</span></Fade>,
                        }}
                     />
                  </form>
               </Grid>
            </GridFHG>
            <Paper elevation={0} className={classes.tableFrameStyle}>
               <ClockInAuditTable isShowDirectReports={isShowDirectReports} isPasswordOnly={isPasswordOnly}
                                  minDateTime={minDateTime} maxDateTime={maxDateTime} isShowPhotos={isShowPhotos}
                                  onDataLoad={handleDataLoad}
               />
            </Paper>
         </GridFHG>
      </GridFHG>
   );
}

export const FaceIdentifyLogInfoFragment = gql`
   fragment FaceIdentifyLogInfo on FaceIdentifyLog {
      id
      createdDateTime
      selectedUserId
      stateId
      selectedUser {
         id
         firstName
         lastName
      }
      matchedUser {
         id
         firstName
         lastName
      }
      timeLog {
         id
         costCenter {
            id
            name
         }
         laborLevel {
            id
            name
         }
      }
      confidence
      clockIn
   }
`;

export const GetAuditEntries = gql`
   query getAuditEntries($limit: Int, $offset: Int, $minDateTime: String, $maxDateTime: String, $lookupTempSupervisors: Boolean, $passwordOnly: Boolean){
      entries:audit_Report (limit: $limit, offset: $offset, minDateTime: $minDateTime, maxDateTime: $maxDateTime, lookupTempSupervisors: $lookupTempSupervisors, passwordOnly: $passwordOnly) {
         ...FaceIdentifyLogInfo,
      }
      confidenceLevel:confidenceLevel_Get
   }
   ${FaceIdentifyLogInfoFragment}
`;

export const GetAuditEntryCount = gql`
   query getAuditEntryCount($minDateTime: String, $maxDateTime: String, $lookupTempSupervisors: Boolean, $passwordOnly: Boolean){
      auditEntryCount:audit_ReportCount (minDateTime: $minDateTime, maxDateTime: $maxDateTime, lookupTempSupervisors: $lookupTempSupervisors, passwordOnly: $passwordOnly)
   }
`;

const SetConfidenceLevel = {
   mutation: gql`
      mutation setConfidenceLevel($confidenceLevel: Int!) {
         confidenceLevel_Set(confidenceLevel: $confidenceLevel)
      }
   `,
   typeKey: 'confidenceLevel.type',
   actionKey: SET_ACTION,
};
