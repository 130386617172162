import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LOGIN_SUCCESS_CONFIRMATION_HIDE } from '../../Constants';
import Typography from '../../fhg/components/Typography';

const styles = theme => ({
   snackbarRoot: {
      backgroundColor: theme.palette.primary.main,
   },
});

// Component to slice the snackbar up.
function TransitionUp(props) {
   return <Slide direction='up' {...props} />;
}

/**
 * Component to show the login confirmation message.
 */
class LoginSuccessSnackbar extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,     // Styles for the component.
      open: PropTypes.bool,                     // Indicates if the Error snackbar should be shown to the user.
      onClose: PropTypes.func,                  // Callback when the snackbar is closed.
      isClockOut: PropTypes.bool,              // Indicates if the user clocked in or out.
   };

   render() {
      const { classes, open, onClose, isClockOut } = this.props;

      return (
         <Snackbar
            open={open}
            variant='success'
            autoHideDuration={LOGIN_SUCCESS_CONFIRMATION_HIDE}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transition={TransitionUp}
            onClose={onClose}
            ContentProps={{
               'aria-describedby': 'message-id',
               classes: {
                  root: classes.snackbarRoot,
               }
            }}
            message={(
               <Typography  color={'inherit'} style={{fontSize: 20}} id={'success.message'} values={{inOut: isClockOut ? 'out' : 'in'}}/>
            )}
         />
      );
   }
}

export default withStyles(styles)(LoginSuccessSnackbar);
