import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Radio from '@material-ui/core/Radio';
import Typography from '../../fhg/components/Typography';
import './LocationTable.css'

const styles = theme => ({
   table: {
      flex: '1 1',
      maxHeight: '100%',
      width: '100%',
      border: 'none',
      userSelect: 'none',
      overflow: 'auto',
      "& .-sort-desc p::after": {
         content: '"keyboard_arrow_down"',
         direction: 'ltr',
         display: 'inline-block',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         // verticalAlign: 'text-top',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
      },
      '& .-sort-asc p::after': {
         content: '"keyboard_arrow_up"',
         direction: 'ltr',
         display: 'inline-block',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         // verticalAlign: 'text-top',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
      },
      '& .rt-th, .rt-td': {
         marginTop: 'auto',
         marginBottom: 'auto',
      },
   },
   emptyHeight: {
      height: `calc(100% - ${theme.spacing(4) + 12}px) !important`,
      minHeight: 200,
   },
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      marginTop: 45,
   },
   columnTitle: {

      // padding: '12px 8px',
      color: 'rgb(0, 172, 193)',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 300,
      lineHeight: '1.42857143rem',
      fontSize: 16,
   },
   selected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
         backgroundColor: `${theme.palette.action.selected} !important`,
      },
   },
});

/**
 * The locations / locations table .
 */
class LocationTable extends React.Component {
   blockScroll = false;

   static propTypes = {
      classes: PropTypes.object.isRequired,     // The style classes for the component.
      defaultLocation: PropTypes.any,              // The default location for this installation.
      data: PropTypes.array,                      // The list of locations from server.
      onDefaultChange: PropTypes.func.isRequired,  // Called when the default location changed.
      selectedId: PropTypes.number,                // The selected location ID.
      isLoading: PropTypes.bool,                   // Indicates if the locations are loading from server.
      onDoubleClick: PropTypes.func,               // Called when the user double clicks a row.
      onClick: PropTypes.func,                     // Called when the user clicks a row.
   };

   constructor(props, context) {
      // noinspection JSCheckFunctionSignatures
      super(props, context);

      // Read the table settings from session storage to restore when the user comes back to this page.
      const locationTableString = sessionStorage.locationTable;
      const locationTable = locationTableString ? JSON.parse(locationTableString) : {sort: [ { id: 'location', desc: true } ]};

      this.state = {
         defaultSort: locationTable.sort,
         defaultLocation: props.defaultLocation,
         selectedValue: undefined,
      };
   }

   // noinspection JSCheckFunctionSignatures
   componentWillReceiveProps(nextProps) {
      if (nextProps.defaultLocation !== this.props.defaultLocation) {
         this.setState({defaultLocation: nextProps.defaultLocation, selectedValue: nextProps.defaultLocation})
      }
   }

   // noinspection JSCheckFunctionSignatures
   componentDidUpdate() {
      this.scrollIntoView();
   }

   handleChange = event => {
      event.preventDefault();
      this.setState({ defaultLocation: event.target.value }, () => {
         this.props.onDefaultChange(this.state.defaultLocation);
      });
   };

   onSortedChange = (newSorted, ) => {
      sessionStorage.locationTable = JSON.stringify({sort: newSorted});
   };

   isRowSelected = (row) => {
       return row && this.props.selectedId === row.original.id;
   };

   scrollIntoView = () => {
      if (this.blockScroll === false) {
         const elements = document.getElementsByClassName(this.props.classes.selected);
         let objDiv = document.getElementsByClassName('rt-tbody')[0];
         if (objDiv && elements.length > 0) {
            objDiv.scrollTop = elements[ 0 ].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
               (elements[ 0 ].offsetHeight / 2);
         }
      }
   };


   render() {
      const { defaultSort } = this.state;
      // noinspection JSMismatchedCollectionQueryUpdate
      const { classes, isLoading=false, data = [] , onDoubleClick, onClick } = this.props;

      return (
         <ReactTable defaultPageSize={10000} minRows={1} loading={isLoading}
                     LoadingComponent={() => isLoading && <CircularProgress className={classes.progress}/>}
                     multiSort={false} className={classNames(classes.table, '-highlight',
                        { [ classes.emptyHeight ]: !data || data.length <= 0 })}
                     data={data}
                     defaultSorted={defaultSort}
                     onSortedChange={this.onSortedChange}
                     showPagination={false}
                     getTrProps={(state, rowInfo) => {
                        return {
                           className: this.isRowSelected(rowInfo) ? classes.selected :undefined,
                           onClick: (e) => {
                              if (!e.defaultPrevented) {
                                 e.preventDefault();
                                 this.blockScroll = true;
                                 if (onClick) {
                                    onClick(rowInfo.original.id, rowInfo.original);
                                 }
                              }
                           },
                           onDoubleClick: (e) => {
                              e.preventDefault();
                              this.blockScroll = true;
                              if (onClick) {
                                 onClick(rowInfo.original.id);
                              }
                              if (onDoubleClick) {
                                 onDoubleClick(rowInfo.original);
                              }
                           },
                        }
                     }}

                     columns={[
                        {
                           id: 'defaultLocation',
                           Header: <Typography className={classes.columnTitle} id={'location.defaultLocation.column'}/>,
                           accessor: row => {
                              return true;
                           },
                           sortable: false,
                           Cell: row => (
                              <Radio
                                 checked={Number(this.state.defaultLocation) === Number(row.original.id)}
                                 onChange={this.handleChange}
                                 onClick={this.handleChange}
                                 value={row.original.id}
                                 name='radio-button-demo'
                                 aria-label='A'
                              />
                           ), // Custom cell components!
                           minWidth: 120,
                        }, {
                           id: 'name',
                           Header: <Typography className={classes.columnTitle} id={'location.name.column'}/>,
                           accessor: 'name',
                           minWidth: 120,
                        }, {
                           id: 'adp',
                           Header: <Typography className={classes.columnTitle} id={'location.adp.column'}/>,
                           accessor: 'adpId',
                           minWidth: 150,
                        },
                     ]}
         />
      );
   }
}

export default withStyles(styles)(withRouter(LocationTable));
