import Zoom from '@material-ui/core/Zoom';
import {useState} from 'react';
import {useImperativeHandle} from 'react';
import {forwardRef} from 'react';
import {useLayoutEffect} from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import React from 'react';
import Webcam from 'react-webcam';
import {useSetRecoilState} from 'recoil';
import {CAMERA_SOUND, CAMERA_VOLUME} from '../../Constants';
import './Video.css';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {dataURLtoBlob} from '../utils/Utils';
import {errorState} from './ErrorStateSnackbar';
import Loading from './Loading';

//width: 640, height: 480
const videoConstraints = {
   width: 640,
   height: 480,
   facingMode: 'environment'
};

const useStyles = makeStyles(() => ({
   photo: {
      backgroundColor: '#FFF',
      objectFit: 'contain',
      width: '100%',
      height: '100%',
   },
   video: {
      width: '100%',
      height: '100%',
   },
   progress: {
      position: 'relative',
      zIndex: 1001,
      display: 'table',
      transform: 'translateY(-50%) translateX(-50%)',
      top: '50%',
      left: '50%',
   }
}), {name: 'VideoStyles'});

const Video = forwardRef(function Video({
   useAudio = false,
   useVideo = true,
   showVideo = true,
   placeHolderImage,
   image,
   onClick,
   style,
   children
}, ref) {
   const classes = useStyles();

   const webcamRef = useRef(null);
   const imageRef = useRef();
   const imageSource = useRef();

   const setErrorState = useSetRecoilState(errorState);

   const [isVideoLoading, setIsVideoLoading] = useState(false);
   const [isVideoOn, setIsVideoOn] = useState(false);
   const [showImage, setShowImage] = useState(!showVideo);

   useEffect(() => {
      if (image) {
         setShowImage(true);
         imageSource.current = image;
      } else {
         imageSource.current = placeHolderImage;
      }
   }, [image, placeHolderImage]);

   useEffect(() => {
      if (showVideo) {
         setShowImage(false);
      } else {
         setShowImage(true);
         if (!imageSource.current) {
            imageSource.current = placeHolderImage;
         }
      }
   }, [placeHolderImage, showVideo]);

   useLayoutEffect(() => {
      return () => {
         if (imageSource.current) {
            URL.revokeObjectURL(imageSource.current);
         }
      }
   });

   useImperativeHandle(ref, () => ({
      async takePhoto() {
         const audio = new Audio(CAMERA_SOUND);
         audio.volume = CAMERA_VOLUME;
         await audio.play();

         const imageSrc = webcamRef.current.getScreenshot();
         imageSource.current = imageSrc;
         setShowImage(true);
         imageRef.current.src = imageSrc
         return {src: imageSrc, blob: dataURLtoBlob(imageSrc)};
      },
      turnOff() {
         setIsVideoLoading(false);
         try {
            webcamRef.current.stopAndCleanup();
         } catch (e) {
            console.log(e);
         }
         setShowImage(true);
         setIsVideoOn(false);
      },
      turnOn() {
         setShowImage(false);
         setIsVideoLoading(true);
         setIsVideoOn(true);
      }
   }));

   const handleUserMedia = () => {
       setIsVideoLoading(false);
   };

   const handleUserMediaError = () => {
       setIsVideoLoading(false);
      setErrorState({errorMessage: 'Camera could not be turned on.'});
   };

   return (
      <div style={style} onClick={onClick}>
         {children}
         {isVideoLoading && <Loading isLoading/> }
         {isVideoOn && (
            <Webcam
               audio={false}
               height={"100%"}
               ref={webcamRef}
               screenshotFormat='image/jpeg'
               width={"100%"}
               videoConstraints={videoConstraints}
               onUserMedia={handleUserMedia}
               onUserMediaError={handleUserMediaError}
               style={{ display: showImage ? 'none' : undefined }}
            />
         )}
         <Zoom in={showImage}>
            <img alt='Camera' className={classes.photo} style={{display: !showImage ? 'none' : undefined}} src={imageSource}
                 ref={imageRef} onError={(e) => {
               // eslint-disable-next-line
               placeHolderImage ? e.target.src = placeHolderImage : undefined
            }}/>
         </Zoom>
      </div>
   );
});

export default Video;
