import Card  from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Typography from '../Typography';

const styles = theme => ({
   root: {
      // marginTop: '10%',
      height: '100vh',
      backgroundColor: theme.palette.background.default,
      width: '100%',
   },
   card: {
      width: 400,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      padding: theme.spacing(1),
   },
   formControl: {
      margin: theme.spacing(1),
   },
   submit: {
      marginTop: theme.spacing(3),
   },
   button: {
      alignSelf: 'center'
   },
   media: {
      height: 100,
      width: 100,
      // marginLeft: 'calc(50% - 50px)',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
   },
});

class SecurityDialog extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,
      logo: PropTypes.string,
      copyrightKey: PropTypes.string,
      promo: PropTypes.node,
      footer: PropTypes.string,
      title: PropTypes.string.isRequired,
      titleKey: PropTypes.string,
      messageKey: PropTypes.string,
      successPath: PropTypes.string,
      isDialog: PropTypes.bool,
      messageColor: PropTypes.string,
      /**
       * The component used for displaying the actions.
       * Either a string to use a DOM element or a component.
       */
      actions: PropTypes.node
      // Actions: PropTypes.any, //oneOfType([PropTypes.element, PropTypes.string, PropTypes.func]),
   };

   static defaultProps = {
      copyrightKey: 'copyright',
      secondaryText: 'Login',
      successPath: '/',
      messageColor: 'default',
   };

   render() {
      const { classes, copyrightKey, logo, actions, children, isDialog, title, titleKey, isOpen, messageKey, message, messageColor } = this.props;

      const contents = (
         <Grid className={!isDialog ? classes.root : undefined} container direction='column' justifyContent='center' spacing={0} alignItems={'center'}>
            <Card className={classes.card}>
               <Grid container direction={'column'} alignItems={'center'}>
                     {logo && (
                        <Grid item style={{alignSelf: 'flex-start'}}>
                           <img alt='' src={logo} style={{ padding: 8, width: 120, height: 100 }}/>
                        </Grid>
                     )}
               <Grid item>
                  <Typography style={{ textAlign: 'center' }} variant={'h4'} id={titleKey}>{title}</Typography>
               </Grid>
               <Grid item>
                  <Typography variant={'body1'} color={messageColor}
                              id={messageKey}>{message}</Typography>
               </Grid>
               </Grid>
               <CardContent>
                  {children}
               </CardContent>
               <CardActions>
                  <Grid container justifyContent='center' spacing={2}>
                     {actions}
                  </Grid>
               </CardActions>
            </Card>
            <Typography variant='caption' id={copyrightKey}/>
         </Grid>
      );

      if (isDialog) {
         return (
            <Dialog open={isOpen}>
               {/*<DialogTitle>*/}
                  {/*<img style={{maxHeight: 30, maxWidth: 30, marginRight: 10}} src={logo}/>*/}
                  {/*/!*{title}*!/*/}
               {/*</DialogTitle>*/}
               {contents}
            </Dialog>
         )
      } else {
         return (
            <div style={{width: '100%'}}>
               {contents}
            </div>
         );
      }
   }
}

export default withStyles(styles)(SecurityDialog);
