import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core//IconButton';
import Toolbar from '@material-ui/core//Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import Menu from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { APP_BAR_HEIGHT } from '../Constants';

const styles = {
   root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 999,
      height: APP_BAR_HEIGHT,
   },
   appBar: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 32,
      paddingRight: 32,
   }
};

/**
 * The App Bar with a logo, title and log out.
 */
class SimpleAppBar extends Component {
   state = {
      drawerOpen: false,
   };

   constructor(props) {
       super(props);

      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {

         if (event.key === 'Escape' || event.key === 'Enter') {
            event.preventDefault();
            this.setState({ drawerOpen: false });
         }
      }
   };

   handleDrawerToggle = () => {
      this.setState({ drawerOpen: !this.state.drawerOpen });

   };

   onCloseDrawer = () => {
      this.setState({ drawerOpen: false });
   };

   render() {
      const { classes, children, logo, color } = this.props;
      const { drawerOpen } = this.state;

      return (<AppBar className={classes.appBar} position='static' color={color}>
            <Toolbar disableGutters={true} style={{ width: '100%' }}>
               <Grid item>
                  {logo}
               </Grid>
               <Grid item style={{ flex: '1 1 auto' }}>
                  <Hidden smDown>
                     <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'}>
                        {children}
                     </Grid>
                  </Hidden>
                  <Hidden mdUp>
                     <Grid container alignItems={'flex-end'} justifyContent={'flex-end'}>
                        <Grid item>
                     <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={this.handleDrawerToggle}
                     >
                        <Menu/>
                     </IconButton>
                        </Grid>
                     </Grid>
                  </Hidden>
               </Grid>
            </Toolbar>
            <Hidden mdUp>
               <Drawer variant='temporary' anchor={'right'} open={drawerOpen} onClose={this.onCloseDrawer}>
                  <Grid container direction={'column'}>
                     {children}
                  </Grid>
               </Drawer>
            </Hidden>
         </AppBar>
      );
   }
}

SimpleAppBar.propTypes = {
   classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleAppBar);
