import {StylesProvider} from '@material-ui/core';
import {CssBaseline} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import {useEffect} from 'react';
import {useState} from 'react';
import React, {Suspense} from 'react';
import {IntlProvider} from 'react-intl';
import {Redirect, Route} from 'react-router-dom';
import Clock from './components/clock/Clock';
import messages from './en-US';
import ErrorBoundary from './fhg/components/ErrorBoundary';
import ErrorStateSnackbar from './fhg/components/ErrorStateSnackbar';
import Loading from './fhg/components/Loading';
import Login from './Login';
import Main from './Main';
import {createTheme} from '@material-ui/core/styles';
import {ImageStorage} from './util/imageUtils';

console.log(messages.copyright);

const overrideTheme = {
   palette: {
      primary: {
         main: '#108397',
      },
      secondary: {
         main: '#B71C1C',
      }
   },
   typography: {
      button: {
         textTransform: 'none',
      }
   },
};

export function showError(errorId) {
   // const setErrorState = useSetRecoilState(errorState);
   //
   // setErrorState({errorKey: errorId});
}

/**
 * Load the messages for the locales.
 * CAVEAT: This is required to code splitting to work.
 */
const messageLoader = {
   en: () => import('./en-US')
}

/**
 * The main entry point for the application.
 */
export default function App() {
   const [messages, setMessages] = useState({});
   // const [client, setClient] = useState();
   const [theme, setTheme] = useState();

   useEffect(() => {
      messageLoader.en().then((messages) => {
         setMessages(messages.default);
      });
      // setClient(createApolloClient());
      setTheme(createTheme(overrideTheme));

      ImageStorage.init();
      return () => {
         ImageStorage.clearExpiredImages();
      }
   }, []);

   if (Object.keys(messages).length > 0) {
      return (
         <Suspense fallback={<Loading isLoading/>}>
            <ErrorBoundary>
               <IntlProvider messages={messages} locale={'en'}>
                     <StylesProvider>
                        <CssBaseline/>
                        <ErrorStateSnackbar/>
                        <ThemeProvider theme={theme}>
                           <Route exact path='/' render={() => <Redirect to='/clock/'/>}/>
                           <Route path='/admin/' component={Main}/>
                           <Route path='/supervisor' component={Main}/>
                           <Route path='/clock/' component={Clock}/>
                           <Route path='/login' component={Login}/>
                        </ThemeProvider>
                     </StylesProvider>
               </IntlProvider>
            </ErrorBoundary>
         </Suspense>
      );
   } else {
      return null;
   }
}
