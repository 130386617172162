import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useState} from 'react';
import useImage from '../../hooks/useImage';
import Grid from './Grid';

export default function Image({id, className, showImage, isIdentityLog = false}) {
   const [localShowImage, setLocalShowImage] = useState(showImage);

   const image = useImage(id, localShowImage, isIdentityLog);

   const handleClick = (event) => {
      event.stopPropagation();
      event.preventDefault();

      setLocalShowImage(true);
   };

   if (!image?.isLoading) {
      return (
         <img id={id} key={'Employee' + id} alt='Employee' className={className} src={image.image} onClick={!showImage
         && !localShowImage ? handleClick : undefined}/>);
   } else {
      return (
         <Grid container fullWidth fullHeight justifyContent={'center'} alignItems={'center'} style={{height: 60}}>
            <Grid item>
               <CircularProgress style={{marginLeft: 'auto', marginRight: 'auto'}} size={30} thickness={2.5}/>
            </Grid>
         </Grid>
      );
   }
}
