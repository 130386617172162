import {lighten} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {useState} from 'react';
import {useMemo} from 'react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Link} from 'react-router-dom';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import GridFHG from '../../fhg/components/Grid';
import {DELETE_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import {ImageStorage} from '../../util/imageUtils';
import {GetEmployees} from './Employee';
import EmployeeTable from './EmployeeTable';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
   outerGridStyle: {
      paddingBottom: 10,
      backgroundColor: '#EBEBEB',
   },
   innerGridStyle: {
      paddingTop: theme.spacing(1),
   },
   tableFrameStyle: {
      margin: 12,
      overflow: 'auto',
      display: 'flex',
      maxHeight: '100%',
   },
   buttonStyle: {
      margin: theme.spacing(1),
      '&:hover': {
         color: theme.palette.error.main,
      }
   },
   deleteColorStyle: {
      backgroundColor: lighten(theme.palette.error.light, 0.7),
      color: 'black',
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}), {name: 'EmployeesStyles'});

/**
 * The list of all current employees showing location and clock in and clock out times.
 */
export default function Employees({search}) {
   const classes = useStyles();
   const history = useHistory();

   const [employeeData, {loading: isLoading}] = useQueryFHG(GetEmployees, undefined, 'employee.type');
   const employees = useMemo(() => employeeData?.employees || [], [employeeData?.employees]);

   const [employeeDelete, {loading: isDeleting}] = useMutationFHG(DeleteEmployee, undefined, true);

   const [isShowPhotos, setIsShowPhotos] = useState(localStorage.showPhotos === 'true');

   const [selectedEmployee, setSelectedEmployee] = useState();

   const isSaving = isDeleting || isLoading;

   const onEdit = (employee) => {
      setSelectedEmployee(employee);

      history.push('/admin/employee/' + employee.id);
   };

   const onSelect = (selectedEmployee) => {
      setSelectedEmployee(selectedEmployee);
   };

   const getEmployeeCacheQueries = () => {
      return [{query: GetEmployees, queryPath: 'employees'}];
   }

   const onDelete = async () => {
      await employeeDelete({
         variables: {id: selectedEmployee?.id},
         optimisticResponse: {user_Delete: 1},
         update: cacheDelete(getEmployeeCacheQueries(), selectedEmployee?.id),
      });
      setSelectedEmployee(undefined);
   };

   const handleChange = event => {
      if (event.target.name === 'showPhotos') {
         setIsShowPhotos(event.target.checked);
         localStorage.showPhotos = event.target.checked;
         if (!event.target.checked) {
            ImageStorage.clear();
         }
      }
   };

   return (
      <GridFHG container className={classes.outerGridStyle} fullHeight direction={'column'}>
         <GridFHG item container className={classes.innerGridStyle} resizable={false} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <GridFHG item>
               <Button component={Link} to='/admin/employee' color={'primary'} variant={'text'} disabled={isSaving}>
                  <AddIcon color={'inherit'}/>
                  Add Employee
               </Button>
               <Button component={Link} to={'/admin/employee/' + selectedEmployee?.id} color={'primary'}
                       variant={'text'}
                       disabled={!selectedEmployee || isSaving}>
                  <EditIcon color={'inherit'}/>
                  Edit
               </Button>
               <ConfirmButton
                  className={classes.buttonStyle}
                  color={'primary'}
                  onConfirm={onDelete}
                  messageKey={'employee.delete.confirm'}
                  values={{name: selectedEmployee?.firstName + ' ' + selectedEmployee?.lastName, type: 'Employee'}}
                  size='large'
                  submitStyle={classes.deleteColorStyle}
                  startIcon={<DeleteIcon/>}
                  buttonTypographyProps={{variant: 'inherit'}}
                  disabled={isSaving || !selectedEmployee}
               />
            </GridFHG>
            <GridFHG container fullWidth={false} resizable={false}>
            <GridFHG item>
               <CheckboxFHG
                  key={'showPhotos'}
                  name={'showPhotos'}
                  onChange={handleChange}
                  labelKey={'employees.showPhoto.label'}
                  value={'showPhotos'}
                  checked={isShowPhotos}
                  disabled={isSaving}
                  marginTop={0}
               />
            </GridFHG>
            </GridFHG>
         </GridFHG>
         <GridFHG item resizable>
            <Paper elevation={0} className={classes.tableFrameStyle}>
               <EmployeeTable isShowPhotos={isShowPhotos} data={employees} search={search} onDoubleClick={onEdit}
                              onClick={onSelect} selectedId={selectedEmployee?.id} disabled={isSaving}
                              isShowImages={isShowPhotos}/>
            </Paper>
         </GridFHG>
      </GridFHG>
   );
}

const DeleteEmployee = {
   mutation: gql`
      mutation deleteEmployee ($id: Int!) {
         user_Delete(userId: $id)
      }
   `,
   typeKey: 'employee.type',
   actionKey: DELETE_ACTION,
};
