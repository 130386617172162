import {Tooltip} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import {useMemo} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {DATE_FORMAT} from '../../Constants';
import Image from '../../fhg/components/Image';
import TypographyFHG from '../../fhg/components/Typography';
import Typography from '../../fhg/components/Typography';
import useLazyQueryFHG from '../../fhg/hooks/data/useLazyQueryFHG';
import {sortDate} from '../../fhg/utils/Utils';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {GetAuditEntryCount} from './ClockInAudit';
import {GetAuditEntries} from './ClockInAudit';

const TOOLTIP_DELAY = 900;

const useStyles = makeStyles(theme => ({
   table: {
      flex: '1 1',
      maxHeight: '100%',
      width: '100%',
      border: 'none',
      userSelect: 'none',
      overflow: 'auto',
      '& .-sort-desc p::after': {
         content: '"keyboard_arrow_down"',
         direction: 'ltr',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         verticalAlign: 'text-top',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
      },
      '& .-sort-asc p::after': {
         content: '"keyboard_arrow_up"',
         direction: 'ltr',
         fontFamily: 'Material Icons',
         fontSize: 24,
         fontStyle: 'normal',
         fontWeight: 'normal',
         letterSpacing: 'normal',
         lineHeight: '1rem',
         textTransform: 'none',
         whiteSpace: 'nowrap',
         wordWrap: 'normal',
         verticalAlign: 'text-top',
         '-webkit-font-feature-settings': 'liga',
         '-webkit-font-smoothing': 'antialiased',
      },
      '& .rt-th, .rt-td': {
         marginTop: 'auto',
         marginBottom: 'auto',
      },
   },
   emptyHeight: {
      height: `calc(100% - ${theme.spacing(4) + 12}px) !important`,
      minHeight: 200,
   },
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      marginTop: 45,
   },
   columnTitle: {
      // padding: '12px 8px',
      color: 'rgb(0, 172, 193)',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 300,
      lineHeight: '1.42857143rem',
      fontSize: 16,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'normal',
   },
   imageStyle: {
      objectFit: 'contain',
      width: 80,
      height: 60,
   },
}), {name: 'ClockInAuditTableStyles'});

/**
 * The list of employees.
 */
export default function ClockInAuditTable({isShowPhotos, isPasswordOnly, isShowDirectReports, minDateTime, maxDateTime, onDataLoad}) {
   const classes = useStyles();

   const [getAuditEntryCount, {data: auditEntryCount={auditEntryCount: 1}}] = useLazyQueryFHG(
      GetAuditEntryCount, {fetchPolicy: 'network-only'}, 'audit.getAuditEntries.error');

   const [getAuditEntries, {data: auditEntriesData, loading: isLoading}] = useLazyQueryFHG(GetAuditEntries,
      {fetchPolicy: 'network-only'},
      'audit.getAuditEntries.error');

   const [defaultSort, setDefaultSort] = useState();
   const entries = auditEntriesData?.entries || [];
   const [pageSize, setPageSize] = useState(10);
   const [reset, setReset] = useState(Date.now());

   useEffect(() => {
      onDataLoad?.(auditEntriesData);
   }, [auditEntriesData, onDataLoad]);

   useEffect(() => {
      const auditTableString = sessionStorage.auditTable;
      const auditTable = auditTableString ? JSON.parse(auditTableString) :
         {sort: [{id: 'photoMatch', desc: true}, {id: 'date', desc: true}]};

      setDefaultSort(auditTable.sort);
   }, []);

   useEffect(() => {
      if (minDateTime !== undefined && maxDateTime !== undefined && isShowPhotos !== undefined &&
         isShowDirectReports !== undefined) {
         setReset(Date.now());

         const variables = {minDateTime, maxDateTime, lookupTempSupervisors: !isShowDirectReports};
         if (isPasswordOnly) {
            variables.passwordOnly = isPasswordOnly;
         }
         getAuditEntryCount({variables});
         getAuditEntries({variables: {limit: pageSize, offset: 0, ...variables}});
      }
      // eslint-disable-next-line
   }, [isShowDirectReports, minDateTime, maxDateTime, isShowPhotos, getAuditEntries, getAuditEntryCount, isPasswordOnly]);

   /**
    * When the user changes the sort. Save to the session storage.
    * @param newSorted
    */
   const onSortedChange = (newSorted) => {
      sessionStorage.auditTable = JSON.stringify({sort: newSorted});
   };

   /**
    * Get the columns for the table.
    * @return {*[]} The table columns.
    */
   const columns = useMemo(() => {
      return [
         {
            id: 'date',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY} title={'Date the punch was recorded.'}><Typography
               className={classes.columnTitle} id={'audit.date.column'}/></Tooltip>,
            accessor: row => {
               return row?.createdDateTime ? moment(+row.createdDateTime).format(DATE_FORMAT) : 'N/A';
            },
            minWidth: 160,
            sortMethod: sortDate
         }, {
            id: 'clockIn',
            Header: (
               <Tooltip enterDelay={TOOLTIP_DELAY} title={'Did employee punch in or out?'}>
                  <Typography className={classes.columnTitle} id={'audit.clockIn.column'}/>
               </Tooltip>
            ),
            accessor: 'clockIn',
            Cell: row => row.value === true ? 'In' : row.value === false ? 'Out' : '',
            minWidth: 100,
         }, {
            id: 'image',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY} title={'Photo captured at time of punch.'}><Typography
               className={classes.columnTitle} id={'audit.logPhoto.column'}/></Tooltip>,
            Cell: row => {
               const id = row?.original?.id;
               return <Image key={id} id={id} alt='Profile' className={classes.imageStyle} showImage={isShowPhotos}
                             isIdentityLog/>;
            },
            sortable: false,
            minWidth: 80,
         }, {
            id: 'photoMatch',
            Header: (
               <Tooltip enterDelay={TOOLTIP_DELAY} title={'Did employee override photo match by entering username?'}>
                  <Typography className={classes.columnTitle} id={'audit.photoMatch.column'}/>
               </Tooltip>
            ),
            accessor: 'stateId',
            Cell: row => row.value === 3 || row.value === 6 ? 'Yes' : 'No',
            minWidth: 110,
         }, {
            id: 'matchedUserFace',
            Header: (
               <Tooltip enterDelay={TOOLTIP_DELAY} title={'Employee recognized as a match.'}>
                  <Typography className={classes.columnTitle} id={'audit.matchedFace.column'}/>
               </Tooltip>
            ),
            Cell: row => {
               const id = row?.original?.matchedUser?.id;
               return <Image key={id} id={id} alt='Profile' className={classes.imageStyle} showImage={isShowPhotos}/>
            },
            sortable: false,
            minWidth: 100,
         }, {
            id: 'matchedName',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY} title={'Name of employee recognized as a match.'}><Typography
               className={classes.columnTitle} id={'audit.matchedName.column'}/></Tooltip>,
            // accessor: row => get(row, 'matchedUser.lastName')  + ', ' + get(row, 'matchedUser.firstName'),
            Cell: row => get(row, 'original.matchedUser.lastName') + ', ' + get(row, 'original.matchedUser.firstName'),
            minWidth: 150,
         }, {
            id: 'selectedUserFace',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY} title={'Photo of employee recorded on the punch.'}><Typography
               className={classes.columnTitle} id={'audit.enteredFace.column'}/></Tooltip>,
            Cell: row => {
               const id = row?.original?.selectedUser?.id;
               return <Image key={id} id={id} alt='Profile' className={classes.imageStyle} showImage={isShowPhotos}/>;
            },
            sortable: false,
            minWidth: 90,
         }, {
            id: 'enteredName',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY} title={'Name of employee recorded on the punch.'}><Typography
               className={classes.columnTitle} id={'audit.enteredName.column'}/></Tooltip>,
            accessor: row => get(row, 'selectedUser.lastName') + ', ' + get(row, 'selectedUser.firstName'),
            minWidth: 150,
         }, {
            id: 'location',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY}
                             title={'Location of employee recorded on the punch.'}><Typography
               className={classes.columnTitle} id={'audit.location.column'}/></Tooltip>,
            accessor: 'timeLog.costCenter.name',
            minWidth: 120,
         }, {
            id: 'laborLevel',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY} title={'Job of employee recorded on the punch.'}><Typography
               className={classes.columnTitle} id={'audit.laborLevel.column'}/></Tooltip>,
            accessor: 'timeLog.laborLevel.name',
            minWidth: 120,
         }, {
            id: 'confidence',
            Header: <Tooltip enterDelay={TOOLTIP_DELAY}
                             title={'Confidence indicator the person identified is the person on the punch.'}><Typography
               className={classes.columnTitle} id={'audit.confidence.column'}/></Tooltip>,
            accessor: 'confidence',
            Cell: row => (row.value && ((row.value * 100).toFixed(2))),
            minWidth: 120,
         },
      ]
   }, [classes.columnTitle, classes.imageStyle, isShowPhotos]);

   const fetchData = (state) => {
      setPageSize(state.pageSize);

      const variables = {minDateTime, maxDateTime, lookupTempSupervisors: !isShowDirectReports};
      if (isPasswordOnly) {
         variables.passwordOnly = isPasswordOnly;
      }
      getAuditEntryCount({variables});
      getAuditEntries({variables: {limit: pageSize, offset: state.page * pageSize, ...variables}});
   };

   return (
      <ReactTable key={'Audit Table' +isShowPhotos + reset} minRows={1} loading={isLoading} pages={Math.ceil(auditEntryCount?.auditEntryCount/pageSize)}
                  manual
                  LoadingComponent={() => isLoading &&
                     <Grid container alignItems={'center'}> <CircularProgress className={classes.progress}/></Grid>}
                  multiSort={false} className={classNames(classes.table, '-highlight',
         {[classes.emptyHeight]: entries?.length <= 0})}
                  data={entries}
                  defaultSorted={defaultSort}
                  defaultPageSize={10}
                  onSortedChange={onSortedChange}
                  showPagination={true}
                  onFetchData={fetchData}
                  noDataText={isLoading ? '' : (<TypographyFHG id={'audit.emptyTable.label'}/>)}
                  columns={columns}
      />
   );
}
