import async from 'async';
import localForage from 'localforage'
import moment from 'moment';
import {PLACEHOLDER_IMAGE_SMALL} from '../Constants';
import {URI} from '../Main';

export const TOP_PRIORITY = 1;
export const MEDIUM_PRIORITY = 2;


export class ImageStorage {
   static priorityQueue;
   static queue;

   static getQueue() {
      return ImageStorage.priorityQueue;
   }

   static addToQueue(priority, key, id, isIdentityLog) {
      return new Promise(function (resolve) {
         const callback = (error, imageData, image) => {
            if (error) {
               console.log(error);
               resolve();
               // reject(error);
            } else {
               ImageStorage.setImage(key, image);
               resolve(image);
            }
         }
         const queue = ImageStorage.getQueue();
         queue.push({id, isIdentityLog}, priority, callback);
         queue.drain();
      });
   }

   static downloadData(imageData, callback) {
      return new Promise(function (resolve, reject) {
         if (imageData?.id) {
            const url = !imageData.isIdentityLog ? `${URI}/api/download/recent?id=${imageData.id}` : `${URI}/api/download?field=image&id=${imageData.id}&table=faceIdentifyLog`;

            fetch(url, {
               credentials: 'include',
               method: 'GET',
            }).then(response => {

               if (response?.status === 200) {
                  return response.blob().then((blob) => {
                     const reader = new FileReader();
                     reader.readAsDataURL(blob);
                     reader.onloadend = () => {
                        const image = reader.result.replace(/data.*;base64,/, 'data:image/png;base64,');
                        callback(null, imageData, image);
                        resolve(image);
                     }
                  }).catch(e => {
                     console.log(e);
                     callback(e, imageData);
                     reject(e);
                  });
               } else {
                  const error = new Error(response.statusText);
                  console.log(error);
                  callback(error, imageData);
                  resolve();
               }
            }).catch((e) => {
               console.log(e);
               callback(e, imageData);
               reject(e);
            });
         } else {
            callback(undefined, imageData, PLACEHOLDER_IMAGE_SMALL);
            resolve(PLACEHOLDER_IMAGE_SMALL);
         }
      });
   }

   static init() {
      ImageStorage.priorityQueue = async.priorityQueue(ImageStorage.downloadData);
      ImageStorage.queue = async.queue(ImageStorage.downloadData);
   }

   static async getImageData(key) {
      try {
         const imageData = await localForage.getItem(key);

         if (imageData) {
            await localForage.setItem(key, {image: imageData.image, lastUsed: Date.now()});
         }

         return imageData;
      } catch (e) {
         console.log(e);
      }
   }

   static async getImage(key) {
      const imageData = await localForage.getItem(key);

      if (imageData) {
         await localForage.setItem(key, {image: imageData.image, lastUsed: Date.now()});
      }

      return imageData?.image;
   }

   static setImage(key, image) {
      const createdDate = Date.now();
      return localForage.setItem(key, {image, lastUsed: createdDate, createdDate});
   }

   static async clear() {
      await localForage.clear();
   }

   static async clearExpiredImages() {
      const expirationMillis = moment().subtract(1, 'week').valueOf();

      const keys = await localForage.keys();

      for await (const key of keys) {
         const image = localForage.getItem(key);

         if (image.lastUsed < expirationMillis) {
            await localForage.removeItem(key);
         }
      }
   }
}
