import {ApolloProvider} from '@apollo/client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
// import {createHttpLink} from 'apollo-link-http';
import { createUploadLink } from "apollo-upload-client";
import {RetryLink} from 'apollo-link-retry';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import {RecoilRoot} from 'recoil';
import App from './App';
import {ENDPOINT} from './Constants';
import registerServiceWorker from './registerServiceWorker';
import localForage from 'localforage';

//Set the Index DB name to Photos.
localForage.config({
   name: 'Photos',
});

localForage.setItem('version', 1);

// const httpLink = createHttpLink({uri:ENDPOINT, credentials: false});
const httpLink = createUploadLink({uri:ENDPOINT, credentials: 'include'});
const retryLink = new RetryLink({
   attempts: {
      max: 2,
      retryIf: (error, _operation) => !!error
   }
});
const client = new ApolloClient({
   link: ApolloLink.from([
      retryLink,
      httpLink,
   ]),
   cache: new InMemoryCache(),
});

ReactDOM.render(
   <RecoilRoot>
      <ApolloProvider client={client}>
         <BrowserRouter>
            <App/>
         </BrowserRouter>
      </ApolloProvider>
   </RecoilRoot>
   , document.getElementById('root'));
registerServiceWorker();
