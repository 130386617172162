import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import gql from 'graphql-tag';
import moment from 'moment';
import {useRef} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import 'react-table/react-table.css';
import {showError} from '../../App';
import {LOGO, SCREEN_SAVER_TIMEOUT} from '../../Constants';
import useEditData from '../../fhg/components/edit/useEditData';
import ScreenSaver from '../../fhg/components/ScreenSaver';
import Login from '../../fhg/components/security/Login';
import Typography from '../../fhg/components/Typography';
import Video from '../../fhg/components/Video';
import {CHECK_ACTION} from '../../fhg/hooks/data/useMutationFHG';
import useMutationFHG from '../../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../../fhg/hooks/data/useQueryFHG';
import ConfirmMatchSnackbar from './ConfirmMatchSnackbar';
import LoginSuccessSnackbar from './LoginSuccessSnackbar';

const useStyles = makeStyles(theme => ({
   root: {
      height: 'calc(100vh - 32px)',
      // minHeight: 580,
      overflow: 'hidden',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(1),
      },
   },
   root2: {
      height: '100%',
      minHeight: 580,
      overflow: 'auto',
   },
   title: {
      overflow: 'visible',
      '&:before': {
         content: '""',
         display: 'inline-block',
         height: 125,
         width: 135,
         position: 'relative',
         marginLeft: -152,
         paddingRight: 16,
         [theme.breakpoints.down('sm')]: {
            marginLeft: -70,
         },
         [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
         },
         background: `url(${LOGO}) no-repeat 0 0`,
         backgroundSize: '135px 135px ',
      }
   },
   formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
   },
   videoStyle: {
      flex: '1 1 100%',
      transform: 'none', left: 'auto', top: 'auto',
   },
}), {name: 'ClockStyles'});

/**
 * The main component for the evaluators. Handled evaluators dashboard and showing the equipment lists or detail.
 */
export default function Clock() {
   const classes = useStyles();
   const history = useHistory();

   const [loginOpen, setLoginOpen] = useState(false);

   const [isClockOut, setIsClockOut] = useState();
   const [currentImage, setCurrentImage] = useState();
   const [faceIdentifyLogId, setFaceIdentifyLogId] = useState();
   const [disconnectError, setDisconnectError] = useState();

   const [confirmOpen, setConfirmOpen] = useState(false);
   const [image, setImage] = useState();
   const [matchEmployee, setMatchEmployee] = useState();

   const [loginConfirmOpen, setLoginConfirmOpen] = useState();
   const [showVideo, setShowVideo] = useState(true);
   const [error, setError] = useState();
   const [errorKey, setErrorKey] = useState();

   const [isSaving, setIsSaving] = useState(false);
   const videoRef = useRef();

   const [
      editValues, handleChange, {
         getValue,
         setValue,
      }
   ] = useEditData({laborLevel: 1});

   const [data] = useQueryFHG(GetLaborLevel, undefined, 'clock.type');
   const {laborLevels = [], identity, departments = [], confidenceLevel, defaultLocation} = data || {};

   const [checkFace, {data: videoData}] = useMutationFHG(CheckFace, undefined, true);
   const [clockIn, {data: clockInData}] = useMutationFHG(ClockIn, undefined, true);
   const [isCameraInitialized, setIsCameraInitialized] = useState(false);

   useEffect(() => {
      if (defaultLocation) {
         setValue('department', defaultLocation?.id);
      }
   }, [defaultLocation, setValue]);

   useEffect(() => {
      const video = videoRef.current;

      if (video && !isCameraInitialized) {
         video.turnOn();
         setIsCameraInitialized(true);
      }
   }, [videoRef.current]);

   useEffect(() => {
      if (data && data?.defaultLocation?.id === undefined) {
         history.push('/admin/locations');
         showError('clock.noLocationSet.error');
      }
   }, [data, history]);

   /**
    * On the login closing, close the dialog and show the video.
    */
   const onLoginClose = () => {
      setLoginOpen(false);
      setShowVideo(true);
      setError(undefined);
      setErrorKey(undefined);
   };

   /**
    * When the user selects Clock In, take the picture and send to the server.
    */
   const onClockIn = () => {
      takePicture(false);
   };

   /**
    * When the user selects Clock Out, take the picture and send to the server.
    */
   const onClockOut = () => {
      takePicture(true);
   };

   /**
    * Take the clock in/out picture.
    * @return {Promise<void>}
    */
   const takePicture = async (isClockOut = false) => {
      const image = await videoRef.current.takePhoto();

      setShowVideo(false);
      setIsSaving(true);
      setIsClockOut(isClockOut);
      setCurrentImage(image);
      try {
         checkFace({variables: {image: image.blob}});
      } catch (e) {
         setShowVideo(true);
         setIsSaving(false);
      }
   };

   useEffect(() => {
      if (videoData) {
         const confidence = videoData?.identity?.users?.[0]?.confidence;
         const disconnectError = videoData?.identity?.disconnectError;

         setShowVideo(false);
         setIsSaving(false);
         setFaceIdentifyLogId(videoData?.identity?.id);
         setDisconnectError(disconnectError);

         if ((confidence * 100) > confidenceLevel) {
            setConfirmOpen(true);
            setLoginOpen(false);
            setImage(currentImage?.src);
            setMatchEmployee(videoData?.identity?.users?.[0] || {});
         } else {
            setLoginOpen(true);
            setImage(currentImage);
         }
      }
   }, [videoData]);

   /**
    * Screen saver is turning on.
    */
   const onScreenSaverShow = () => {
      videoRef.current.turnOff();
   };

   /**
    * The screen saver is hiding so show the video.
    */
   const onScreenSaverHide = () => {
      setShowVideo(true);
      videoRef.current.turnOn();
   };

   /**
    * On the user entering username and password, close the login dialog, show the video and the confirmation.
    *
    * @param username The user's username.
    * @param password The user's password.
    * @return {Promise<void>}
    */
   const onLoginSubmit = async (username, password) => {

      try {
         clockIn({
            variables: {
               department: getValue('department'),
               username,
               password,
               laborLevelId: getValue('laborLevel'),
               faceIdentifyLogId,
               isClockOut,
               disconnectError
            }
         });
      } catch (error) {
         setError('Bad password or invalid username.');
         setErrorKey('login.error');
      }
   };

   useEffect(() => {
      if (clockInData) {
         setLoginOpen(false);
         setLoginConfirmOpen(true);
         setShowVideo(true);
         setError(undefined);
         setErrorKey(undefined);
         setValue('department', defaultLocation?.id);
      }
   }, [clockInData]);

   /**
    * On the user closing the login confirm dialog, close the dialog.
    */
   const onLoginConfirmClose = () => {
      setLoginConfirmOpen(false);
   };

   /**
    * On the user confirming the match, send the data to the server and show the video.
    * @return {Promise<void>}
    */
   const onConfirmClose = async (event) => {
      // The event will be null if the Snackbar timer Times out. Our custom timer should time out first causing the
      // other timeout not to occur.
      if (event !== null) {
         try {
            clockIn({
               variables: {
                  id: matchEmployee.id,
                  department: getValue('department'),
                  laborLevelId: getValue('laborLevel'),
                  faceIdentifyLogId,
                  isClockOut,
                  disconnectError
               }
            });
            setConfirmOpen(false);
            setLoginConfirmOpen(true);
            setShowVideo(true);
            setValue('department', defaultLocation?.id);
         } catch (error) {
            setError('Could not register clock in/out.');
            setErrorKey('clock.clock.error');
         }
      }
   };

   /**
    * On the user indicating a bad match, open the login.
    */
   const onIncorrect = () => {
      setConfirmOpen(false);
      setLoginOpen(true);
   };

   const date = new Date();

   return (
      <div className={classes.root}>
         <Grid container direction='column' className={classes.root2} justifyContent={'center'} alignItems={'center'}
               spacing={1} wrap={'nowrap'}>
            <ConfirmMatchSnackbar onConfirm={onConfirmClose} onReject={onIncorrect} open={confirmOpen}
                                  employee={matchEmployee}/>
            <LoginSuccessSnackbar onClose={onLoginConfirmClose} open={loginConfirmOpen} isClockOut={isClockOut}/>
            <ScreenSaver onTimeout={onScreenSaverShow} onWakeUp={onScreenSaverHide}
                         isEnabled={!loginOpen} timeout={SCREEN_SAVER_TIMEOUT}>
               <Grid container alignItems={'center'} justifyContent={'center'} spacing={0}>
                  <Grid item>
                     <img alt='Logo' src={LOGO}/>
                  </Grid>
                  <Grid item>
                     Move mouse to turn on facial recognition system.
                  </Grid>
               </Grid>
            </ScreenSaver>
            <Grid item className={classes.title}>
               <Typography color='textSecondary' style={{
                  fontSize: '3.5rem',
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textAlign: 'center',
                  width: '100%',
                  marginLeft: 16
               }}
                           variant={'h2'} id={'clock.title'}/>
            </Grid>
            <Grid item>
               <Typography variant={'h6'} id={'clock.date.label'}
                           values={{dateTime: moment(date).format('M/D/YYYY hh:mm a')}}>Current Date/Time:
                  N/A</Typography>
            </Grid>
            <Grid item style={{display: 'flex', minHeight: 200, minWidth: 200}}>
               <Video ref={videoRef} showVideo={showVideo}
                      className={classes.videoStyle}
               />
            </Grid>
            <Grid item>
               <FormControl className={classes.formControl}>
                  <InputLabel htmlFor='jobId'>Job</InputLabel>
                  <Select
                     id={'jobId'}
                     name={'laborLevel'}
                     value={getValue('laborLevel')}
                     onChange={handleChange}
                     inputProps={{
                        name: 'laborLevel',
                        style: {fontSize: 18}
                     }}
                  >
                     {laborLevels?.map((laborLevel) => (
                           <MenuItem key={'laborLevel' + laborLevel.id} value={laborLevel.id}>{laborLevel.name}</MenuItem>
                        )
                     )}
                  </Select>
               </FormControl>
               <FormControl className={classes.formControl}>
                  <InputLabel htmlFor='departmentId'>Department</InputLabel>
                  <Select
                     id={'departmentId'}
                     name={'department'}
                     value={getValue('department')}
                     onChange={handleChange}
                     inputProps={{
                        name: 'department',
                        style: {fontSize: 18}
                     }}
                  >
                     {departments?.map(departments => (
                           <MenuItem key={'departments' + departments.id}
                                     value={departments.id}>{departments.name}</MenuItem>
                        )
                     )}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item>
               <Button style={{backgroundColor: 'rgb(0,163,185)', color: 'white', marginRight: 16}}
                       variant={'contained'}
                       onClick={onClockIn} disabled={isSaving}>
                  <PhotoCameraIcon style={{marginRight: 6}}/>
                  <Typography id={'clock.in.label'} color={'inherit'}/>
               </Button>
               <Button color={'default'} variant={'contained'} disabled={isSaving}
                       onClick={onClockOut}>
                  <PhotoCameraIcon style={{marginRight: 6}}/>
                  <Typography id={'clock.out.label'}/>
               </Button>
            </Grid>
            <Login titleKey={'noMatch.title'} title={'No Match'} showForgot={false} isDialog={true}
                   onClose={onLoginClose}
                   isOpen={loginOpen} messageKey={'noMatch'} logo={LOGO} errorMessage={error}
                   errorMessageKey={errorKey} hasCancel={true} submitButtonKey='validate.button.label'
                   onSubmit={onLoginSubmit}/>
         </Grid>
      </div>
   );
}

const GetLaborLevel = gql`
   query getLaborLevel  {
      laborLevels:laborLevel_All {
         id
         name
      }
      defaultLocation:costCenter_GetDefault {
         id
      }
      departments:costCenter_All {
         id
         adpId
         name
      }
      confidenceLevel:confidenceLevel_Get
   }
`;

const CheckFace = {
   mutation: gql`
      mutation checkFace($image: Upload!) {
         identity: face_Identify(file: $image) {
            id
            users {
               id
               firstName
               lastName
               confidence
            }
            disconnectError
         }
      }
   `,
   typeKey: 'checkFace.type',
   actionKey: CHECK_ACTION,
};

const ClockIn = {
   mutation: gql`
      mutation clockIn($id: Int, $isClockOut: Boolean, $faceIdentifyLogId: Int!, $laborLevelId: Int!, $department: Int, $username: String, $password: String, $disconnectError: Boolean!) {
         log:timeLog_Log(userId: $id, clockOut: $isClockOut, faceIdentifyLogId: $faceIdentifyLogId, laborLevelId: $laborLevelId, costCenterId: $department, username: $username, password: $password, disconnectError: $disconnectError) {
            id
            locationId:costCenterId
         }
      }
   `,
   typeKey: 'clockIn.type',
   actionKey: CHECK_ACTION,
};
