import {useMutation} from '@apollo/react-hooks';
import uniqueId from 'lodash/uniqueId';
import {useCallback} from 'react';
import {useState, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useSetRecoilState} from 'recoil';
import {SERVER_CONNECTION_ERROR} from '../../../Constants';
import {errorState} from '../../components/ErrorStateSnackbar';
import {formatMessage} from '../../utils/Utils';
import {useEffect} from 'react';
import useProgress from '../useProgress';

export const ADD_ACTION = 'add';
export const SET_ACTION = 'set';
export const UPDATE_ACTION = 'update';
export const DELETE_ACTION = 'delete';
export const CHECK_ACTION = 'check';

function serverError(errorMsg) {
   let prettyMessage = errorMsg.replace('GraphQL error:', ' ').trim();
   if (prettyMessage.indexOf(SERVER_CONNECTION_ERROR) >= 0) {
      prettyMessage = 'Offline';
   }
   return prettyMessage;
}

/**
 * Hook for useMutation that updates the cache for add and delete queries. Update mutations should automatically update
 * cache without this update.
 *
 * NOTE:
 * 1) Assumes that the result of the mutation only has a single property. (e.g. {data: {operators: {...}}})
 * 2) Updates ONLY the FIRST property in an updateQuery. The first property is assumed to be a list and adds the result
 *    property to the list. Other properties in the original query are copied to the updated cache item.
 *
 * Reviewed:
 *
 * @param mutation The graphql mutation.
 *    typeKey - The localization key for the type of the object
 *    actionType - The localization key for the action type (e.g. create, update, delete).
 * @param options The options for the mutation.
 * @param showLoading Indicates if the progress should be shown.
 * @param messageKey An optional messageKey for the error message.
 * @return
 */
export default function useMutationFHG(mutation, options, showLoading = true, messageKey) {
   const theUniqueId = useRef(uniqueId()).current;
   const intl = useIntl();
   const [/*Unused*/, setProgress] = useProgress(theUniqueId);

   const setErrorState = useSetRecoilState(errorState);
   const resolveRef = useRef();

   const [lastMessage, setLastMessage] = useState('');

   useEffect(() => {
      return () => {
         setProgress(false);
      }
   }, [setProgress]);

   const [mutationFunction, result] = useMutation(mutation.mutation, options);

   useEffect(() => {
      if ((result.called || result.data) && !result.loading && resolveRef.current) {
         resolveRef.current(result)
         resolveRef.current = undefined
      }
   }, [result]);


   useEffect(() => {
      if (result.error) {
         let errorMessage;

         if (messageKey) {
            errorMessage = formatMessage(intl, messageKey, undefined, {message: serverError(result.error.message) });
         } else {
            const type = formatMessage(intl, mutation.typeKey);
            const action = formatMessage(intl, mutation.actionKey);
            errorMessage = formatMessage(intl, 'action.error', undefined, {type, action});
         }

         if (errorMessage !== lastMessage) {
            console.log(result.error);
            setLastMessage(errorMessage);
            setErrorState({error: result.error, errorMessage, errorKey: undefined});
         }
      } else if (lastMessage !== undefined){
         setLastMessage(undefined);
      }
   }, [result.error, setErrorState, lastMessage, intl, mutation.actionKey, mutation.typeKey, messageKey]);

   useEffect(() => {
      if (showLoading) {
         setProgress(result.loading);
      }
   }, [result.loading, setProgress, showLoading]);

   const localMutationFunction = useCallback((variables, context) => {
      mutationFunction(variables, context);

      return new Promise((resolve) => {
         resolveRef.current = resolve;
      });
   }, [mutationFunction]);

   return [localMutationFunction, result];
}
