import {TextField, FormControlLabel, Checkbox, Button, FormControl} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import {withStyles} from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Typography from '../Typography';
import SecurityDialog from './SecurityDialog';

const styles = theme => ({
   root: {
      // marginTop: '10%',
      height: '100vh',
   },
   card: {
      width: 400,
      marginBottom: theme.spacing(3),
   },
   formControl: {
      margin: theme.spacing(1),
   },
   submit: {
      marginTop: theme.spacing(3),
   },
   button: {
      alignSelf: 'center',
      marginLeft: theme.spacing(1),
   },
   media: {
      height: 100,
      width: 100,
      // marginLeft: 'calc(50% - 50px)',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
   },
});

class Login extends Component {

   static propTypes = {
      classes: PropTypes.object.isRequired,
      logo: PropTypes.string,
      copyrightKey: PropTypes.string.isRequired,
      promo: PropTypes.node,
      footer: PropTypes.string,
      title: PropTypes.string.isRequired,
      titleKey: PropTypes.string,
      showForgot: PropTypes.bool,
      rememberMe: PropTypes.bool,
      usernameType: PropTypes.string,
      secondaryText: PropTypes.string,
      messageKey: PropTypes.string,
      successPath: PropTypes.string,
      onSubmit: PropTypes.func,
      submitButtonKey: PropTypes.string,
      hasCancel: PropTypes.bool,
      isValid: PropTypes.bool,
      onSuccess: PropTypes.func,
      isOpen: PropTypes.bool.isRequired,        //Indicates if the login dialog should be showing or not.
   };

   static defaultProps = {
      copyrightKey: 'copyright',
      rememberMe: true,
      showForgot: true,
      usernameType: 'text',
      secondaryText: 'Login',
      successPath: '/',
      submitButtonKey: 'login.login',
      hasCancel: false,
      isValid: true,
   };

   state = {};

   //Constructor
   constructor(props, context) {
      super(props, context);

      this.state = {
         message: undefined,
         username: '',
         password: '',
         showPassword: false,
      };
      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.isOpen !== this.props.isOpen) {
         this.setState({message: undefined, username: '', password: '', showPassword: false});
      }
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      const { hasCancel, onClose  } = this.props;

      if (this.props.isOpen) {
         if (!event.defaultPrevented) {
            if (event.key === 'Enter' && this.isValid()) {
               event.preventDefault();
               this.onSubmit();
            }
         } else if (hasCancel && onClose && event.key === 'Escape') {
            event.preventDefault();
            onClose();
         }
      }
   };

   //Methods
   handleChange = prop => event => {
      this.setState({ [ prop ]: event.target.value });
   };

   handleMouseDownPassword = event => {
      event.preventDefault();
   };

   handleClickShowPasssword = () => {
      this.setState({ showPassword: !this.state.showPassword });
   };

   /**
    * Determines if the login dialog has valid information. Since the user must type the username and password correctly
    * to match, there is no validation of the email.
    *
    * @return {boolean} True if the password and username have something entered in them.
    *
    * @private
    */
   isValid = () => {
      const { isValid } = this.props;
      const { username = '', password = '' } = this.state;

      return isValid && username.length > 0 && password.length > 0;
   };

   /**
    * Get the cookie for redirecting the user to the page first requested when the login dialog was displayed.
    *
    * @param name The name of the cookie.
    *
    * @return {*} The cookie requested if found.
    */
   getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);

      console.log('Login::getCookie() ');

      if (parts.length === 2) {
         let ret = parts.pop().split(';').shift();
         ret = ret.split('=');
         return ret[ 0 ];
      }
   }

   /**
    * Callback when the user submits the login. Redirects the user to the previously requested page.
    *
    * @private
    */
   onSubmit = async () => {
      const { messageKey, successPath, onSubmit, history, onSuccess } = this.props;
      const url = process.env.NODE_ENV === 'production' ? '/auth/local/login' : `https://${process.env.REACT_APP_ENDPOINT}/auth/local/login`;

      this.setState({ isSaving: true });

      // create a string for an HTTP body message
      let { username, password, remember } = this.state;

      username = username.trim();

      password = password.trim();
      // TODO: Replace deprecated function call
      // const key = '';
      // // noinspection JSUnresolvedFunction
      // const cipher = crypto.createCipher('aes128', key);
      // let hash = cipher.update(password, 'utf8', 'hex');
      // // noinspection JSUnresolvedFunction
      // hash += cipher.final('hex');
      const hash = password;

      if (onSubmit) {
         onSubmit(username, hash);
      } else {

         try {
            const res = await fetch(url, {
               credentials: 'include',
               method: 'POST',
               headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                  username,
                  password: hash,
                  remember
               })
            });
            const response = await res.json();
            if (response.success) {
               if (onSuccess) {
                  onSuccess();
               }
               // change the current URL to search
               let redirect = this.getCookie('redirect_url');
               if (redirect !== undefined) {
                  redirect = redirect.replace(/%2F/g, '/');
                  window.location.replace(redirect);
               } else {
                  history.replace(successPath);
               }
            } else if (response.message.indexOf('ResourceRequest timed out') >= 0){
               this.setState({errorMessage: 'Error logging in', isSaving: false})
            } else {
               let errorMessage = messageKey || 'Invalid username or password.';
               this.setState({errorMessage, isSaving: false})
            }
         } catch ({ response, xhr }) {
            let message = messageKey || 'Invalid username or password.';
            if (xhr) {
               console.log(`Login failed - ${(response && response.message) || ''} (${xhr.status}:${xhr.statusText})`);
            }
            this.setState({ message, isSaving: false })
         }
      }
   };


   render() {
      const { classes, copyrightKey, logo, children, title, titleKey, isDialog, isOpen, showForgot, actions, message,
         messageKey, errorMessageKey, submitButtonKey, hasCancel, onClose } = this.props;
      const errorMessage = this.props.errorMessage || this.state.errorMessage;

      const loginActions = actions || [(
         <Button variant='contained' color='primary' className={classes.button} onClick={this.onSubmit}
                 disabled={!this.isValid()}>
            {<Typography color='inherit' variant='body1' id={submitButtonKey}>LOG IN</Typography>}
         </Button>
      )];

      if (hasCancel && !actions) {
         loginActions.push(
            (
               <Button variant='contained' className={classes.button} onClick={onClose}>
                  {<Typography color='inherit' variant='body1' id={'cancel.button'}/>}
               </Button>
            )
         );
      }
      return (
         <SecurityDialog logo={logo} actions={loginActions} copyrightKey={copyrightKey} titleKey={titleKey}
                         title={title} isDialog={isDialog}
                         messageColor={errorMessage || errorMessageKey ? 'error' : 'default'}
                         isOpen={isOpen} messageKey={errorMessageKey || messageKey} message={errorMessage || message}
         >
            <TextField
               key='username'
               id='username'
               error={errorMessage || errorMessageKey}
               label={<Typography variant='body2' id='login.username'/>}
               className={classes.formControl}
               value={this.state.name}
               onChange={this.handleChange('username')}
               margin='normal'
               fullWidth
            />
            <FormControl key='password' className={classes.formControl} fullWidth error={errorMessage || errorMessageKey}>
               <InputLabel htmlFor='password'>{<Typography variant='body2'
                                                           id='login.password'/>}</InputLabel>
               <Input
                  id='adornment-password'
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  endAdornment={
                     <InputAdornment position='end'>
                        <IconButton key={'passwordVisibility'}
                           onClick={this.handleClickShowPasssword}
                           onMouseDown={this.handleMouseDownPassword}
                        >
                           {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                     </InputAdornment>
                  }
               />
            </FormControl>
            {showForgot &&
            <FormControl key={'forgotPassword'} className={classes.formControl} fullWidth>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={this.state.jason}
                        onChange={this.handleChange('jason')}
                        value='jason'
                     />
                  }
                  label={<Typography variant='body2' id='login.forgot'/>}
               />
            </FormControl>
            }
            {children}
         </SecurityDialog>
      );
   }
}

export default withRouter(withStyles(styles)(Login));
