import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isObjectLike from 'lodash/isObjectLike';
import _toNumber from 'lodash/toNumber';
import moment from 'moment';
import {DATE_FORMAT} from '../../Constants';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({ id, defaultMessage: newDefaultMessage }, values) : newDefaultMessage;
   } else {
      return '';
   }
}

export const emptyFunction = () => {};

export const stringToBoolean = (string) => {
   if (string) {
      switch (string.toLocaleLowerCase().trim()) {
         case 'true':
         case 'yes':
         case '1':
            return true;
         case 'false':
         case 'no':
         case '0':
            return false;
         default:
            return string;
      }
   } else {
      return string;
   }
};

export function removeOne(array, index) {
   if (index >= 0 && array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
   return array;
}

export function validateEmail(email) {
   if (email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
         email);
   }
   return true;
}

export function validatePhoneNumber(number, separator = false) {
   if (number) {
      if (separator) {
         return /^([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/.test(number);
      }

      return /^\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/.test(number);
   }
   return true;
}

/**
 * Create a blob from base 64 data.
 *
 * @param {string} b64Data    The base64 data possibly returned from the server.
 * @param {string} contentType   The type of data (e.g. 'image/jpeg').
 * @param {number} sliceSize     The size of the slice to use. 512 seems to be fairly optimal and may not need to be
 *    changed.
 * @return {Blob}       The resulting blob from the base 64 data.
 */
export function base64toBlob(b64Data, contentType = '', sliceSize = 512) {
   try {
      let byteCharacters = atob(b64Data);
      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);

         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
            byteNumbers[ i ] = slice.charCodeAt(i);
         }

         const byteArray = new Uint8Array(byteNumbers);

         byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
   } catch (e) {
      console.log(e);

   }
}

/**
 * Get an object containing all the changed properties from object that are different than the original.  The properties
 * in the object can be a subset of the original.
 *
 * @param {object} object  The changed object that is being compared to original.
 * @param {object} original The original properties.
 *
 * @return {object} containing only the changed properties.
 */
export function changedProperties(object, original) {
   const changed = {};

   for (let property in object) {
      if (object.hasOwnProperty(property)) {
         if (!original.hasOwnProperty(property) || object[ property ] !== original[ property ] || property === 'id') {
            changed[ property ] = object[ property ];
         }
      }
   }
   return changed;
}

export function passwordLength(value) {
   if (value) {
      if (value.length < 8 || value.length > 20) {
         return false;
      }
   }
   return true;
}

export function isRequired(input) {
   return !!input && input !== 0;
}

export function isPasswordMatch(password = '', passwordConfirm = '') {
   return password.trim() === passwordConfirm.trim();
}

export const sortDate = (a, b) => {

   if (a === b) {
      return 0;
   }
   if (a === undefined || a === 'Invalid date' || b === undefined || b === 'Invalid date') {
      return (a === undefined || a === 'Invalid date') ? -1 : 1;
   }
   const aMoment = moment(a, DATE_FORMAT);
   const bMoment = moment(b, DATE_FORMAT);

   if (aMoment.isSame(bMoment)) {
      return 0;
   }
   return aMoment.isAfter(bMoment) ? 1 : -1;
};

export function toNumber(value, isAllowBlank = true) {
   if (value === null || value === undefined || (isAllowBlank && value === '')) {
      return null;
   } else{
      return _toNumber(value);
   }
}

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return !isNil(item) && item !== '' && (!isArray(item) || item.length > 0) &&
      (!isObjectLike(item) || Object.keys(item).length > 0);
}

export const editChange = (event, value, reason, isComponent = true, newValue, name) => {
   let nextValue;
   let componentName = name;

   if (newValue === undefined) {
      if (value && (reason === 'blur' || reason === 'create-option' || reason === 'select-option')) {
         nextValue = typeof value === 'string' ? value : value.id;
         componentName = name || get(event, 'target.parentElement.dataset.optionname') ||
            get(event, 'target.firstElementChild.dataset.optionname') || event.target.name;
      } else if (value && reason === 'date-picker') {
         nextValue = value;
         componentName = event.target.name;
      } else {
         if (event && event.target) {
            switch (event.target.type) {
               case 'number':
                  nextValue = event.target.valueAsNumber;
                  break;
               case 'checkbox':
                  nextValue = event.target.checked;
                  break;
               case 'date-range':
                  nextValue = event.target.date;
                  break;
               case 'react-select':
                  nextValue = event.target.value;
                  break;
               case 'react-number-format':
                  nextValue = toNumber(event.target.value);
                  break;
               default:
                  const type = get(event, 'target.dataset.type');
                  if (type === 'number') {
                     nextValue = toNumber(event.target.value);
                  } else {
                     nextValue = event.target.value;
                  }
                  break;
            }
            componentName = event.target.name;
         } else {
            console.log('event.target is null');
         }
      }
   }

   if (newValue) {
      return isComponent ? newValue : {componentName, newValue: newValue[name]};
   } else if (isComponent) {
      return {[componentName]: nextValue};
   }
   return {componentName, newValue: nextValue};
};

export const dataURLtoBlob = (dataurl) => {
   if (dataurl) {

      let arr = dataurl.split(','), mime = arr?.[0]?.match(/:(.*?);/)[1],
         bstr = atob(arr?.[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
         u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type:mime});
   } else {
      return null;
   }
}
