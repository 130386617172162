import {forEach} from 'lodash';
import {forOwn} from 'lodash';
import get from 'lodash/get';
import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import {DATE_FORMAT} from '../../Constants';
import {removeOne} from './Utils';

/**
 * Update the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to update. (e.g. {query, variables, queryPath})
 * @param id of the item to update.
 * @param mutationPathProp Property name for the property to update coming back from the mutation.
 * @return {function: void} The function for update.
 */
export const cacheUpdate = (queryList, id, mutationPathProp) => {
   const useQueryList = castArray(queryList);

   if (id !== undefined) {
      return (proxy, {data}) => {
         for (const queryItem of useQueryList) {
            const {query, variables, queryPath = mutationPathProp, mutationPath = mutationPathProp} = queryItem;
            const resultData = get(data, mutationPath);
            try {
               const cachedData = proxy.readQuery({query, variables});
               const itemIndex = findIndex(cachedData[queryPath], {id});
               let arr;

               if (itemIndex >= 0) {
                  arr = [...cachedData[queryPath]];
                  arr[itemIndex] = resultData;
               } else {
                  arr = [...(cachedData[queryPath] || []), resultData];
               }
               proxy.writeQuery({query, variables, data: {...cachedData, [queryPath]: arr}});
            } catch (e) {
               if (process.env.NODE_ENV !== 'production') {
                  console.log('Failed to update cache.', e);
               }
            }
         }
      }
   } else {
      return cacheAdd(useQueryList, mutationPathProp);
   }
};

/**
 * Add the new item to the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to add the result item. (e.g. {query, variables, queryPath})
 * @param mutationPath Property name resulting object being updated.
 * @return {function: void} The function for the update.
 */
export const cacheAdd = (queryList, mutationPath) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = mutationPath} = queryItem;

         const resultData = get(data, mutationPath);
         // Read the data from our cache for this query.
         const cachedData = proxy.readQuery({query, variables});
         // Write our data back to the cache with the new comment in it
         const newArray = [...(cachedData[queryPath] || []), resultData];
         const newData = {...cachedData, [queryPath]: newArray};
         proxy.writeQuery({query, variables, data: newData});
      }
   };
}

/**
 * Delete the item add the id from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at id. (e.g. {query, variables, queryPath})
 * @param id The id of the item to delete in the cache.
 * @param path Property name resulting object being updated.
 * @return {function: void} Function for update.
 */
export const cacheDelete = (queryList, id, path) => {
   const useQueryList = castArray(queryList);

   return (proxy) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = path} = queryItem;

         const cachedData = proxy.readQuery({query, variables});
         const itemIndex = findIndex(cachedData[queryPath], {id});
         if (itemIndex >= 0) {
            const modifiedList = removeOne([...cachedData[queryPath]], itemIndex);
            proxy.writeQuery({
               query,
               variables,
               data: {...cachedData, [queryPath]: modifiedList.length > 0 ? modifiedList : null}
            });
         }
      }
   };
}

/**
 * Assign the fields from all items. Priority is left to right. If the field has a value it will not be overwritten.
 * @param primary The most important priority object. Set fields will not be overwritten.
 * @param items Array of items to provide values.
 */
export function assign(primary, ...items) {
   const result = {...primary};

   forEach(items, item => {
      forOwn(item, (value, key) => {
         if (result[key] === undefined) {
            result[key] = value;
         }
      });
   });
   return result;
}

export function convertTime(data, path, format = DATE_FORMAT, defaultTime = 'N/A') {
   const timeString = get(data, path);

   if (timeString) {
      return moment(+timeString).format(format);
   }
   return defaultTime;
}
